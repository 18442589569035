import {combineReducers} from 'redux';
import ajaxCallsInProgress from './ajaxStatusReducer';
import exhibitors from './exhibitorsReducers';
import events from './eventsReducers';
import individual from './individualReducers';
import expo from './expoReducers';
import synced from './syncReducers';

const rootReducer = combineReducers({
    ajaxCallsInProgress,
    exhibitors,
    events,
    individual,
    expo,
    synced
});

export default rootReducer;
