import React from  'react';
import PropTypes from 'prop-types';
import ExhibitorItem from './ExhibitorItem';
import ExhibitorItemPrint from './ExhibitorItemPrint';
import { Link } from 'react-router-dom';

const propTypes = {
  individual: PropTypes.object.isRequired,
  expo: PropTypes.object.isRequired,
  exhibitors: PropTypes.array.isRequired,
  excludedList: PropTypes.bool,
  showHideExcludedList: PropTypes.func,
  excludedListVisible: PropTypes.bool,
  setInterestLevel: PropTypes.func.isRequired,
  onWebsiteClick: PropTypes.func.isRequired,
  onSendEmailClick: PropTypes.func.isRequired,
  printVersion: PropTypes.bool
};

const ExhibitorsList = ({individual, expo, exhibitors, excludedList, excludedListVisible, showHideExcludedList, setInterestLevel, onWebsiteClick, onSendEmailClick, printVersion}) => {
  const print = printVersion || false;
  return (
    <div>
    {!excludedList && <div>
      {expo && expo.guide_settings && expo.guide_settings && <div
        style={{backgroundColor: expo.guide_settings.guide_main_color}}
        className="guide-data-box hidden-print">
        <div className="col-md-12 padding-left-0 padding-right-0">
          <span className="title text-white">
            {`Selected Exhibitors (${exhibitors.length})`}
          </span>
        </div>
      </div>}
      <div style={{marginTop: '-10px'}}>
        {!print && exhibitors.map(exhibitor =>
          <ExhibitorItem
                        key={exhibitor.id}
                        expo={expo}
                        exhibitor={exhibitor}
                        setInterestLevel={setInterestLevel}
                        onWebsiteClick={onWebsiteClick}
                        onSendEmailClick={onSendEmailClick}
                       />
         )}
         {print && exhibitors.map(exhibitor =>
           <ExhibitorItemPrint
                         key={exhibitor.id}
                         expo={expo}
                         exhibitor={exhibitor}
                        />
          )}
      </div>
    </div>
  }
  {excludedList &&
    <div>
      <div
        onClick={showHideExcludedList}
        style={{backgroundColor: 'rgba(102, 102, 102, 1)', cursor:'pointer'}}
        className="guide-data-box hidden-print">
        <div className="col-md-12 padding-left-0 padding-right-0">
          <span className="title text-white">
            {`Excluded Exhibitors (${exhibitors.length})`}
          </span>
        </div>
      </div>
      {excludedListVisible && <div style={{marginTop: '-10px'}}>
        {exhibitors.map(exhibitor =>
          <ExhibitorItem
            key={exhibitor.id}
            expo={expo}
            exhibitor={exhibitor}
            setInterestLevel={setInterestLevel}
            onWebsiteClick={onWebsiteClick}
            onSendEmailClick={onSendEmailClick}
           />
        )}
      </div>}
    </div>
}
</div>
  );
};

ExhibitorsList.propTypes = propTypes;

export default ExhibitorsList;
