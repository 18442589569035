import React from "react";
import PropTypes from "prop-types";
import Routes from "./routes";
import { AuthProvider } from "./context/auth";

const propTypes = {};

const App = () => {
  return (
    <div>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </div>
  );
};

App.propTypes = propTypes;

export default App;
