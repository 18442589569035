import React, { useEffect, useState } from 'react';
import TextInput from '../common/TextInput';
import guideApi from '../../api/guideApi';
import { useHistory } from "react-router-dom";

const HomePage = (props) => {
  const [errors, set_errors] = useState({})
  const [badge_number, set_badge_number] = useState('')
  const [name_last, set_name_last] = useState('')
  const [email, set_email] = useState('')
  const [domain, set_domain] = useState(window.location.hostname)
  const [expo_id, set_expo_id] = useState('')
  const history = useHistory()

  useEffect(() => {
    if (domain === 'localhost' || domain === 'itaguides.thenexus.net') {
      set_expo_id('3f458c5e-0999-4214-b21e-8faa64d1a3bd')
    }
  }, [domain])

  function searchIndividual(event) {
    event.preventDefault();
    let foundErrors = false;

    let _errors = {};
    if (!badge_number) {
      _errors.badge_number = 'This field is required.';
      foundErrors = true;
    }
    if (!name_last) {
      _errors.name_last = 'This field is required.';
      foundErrors = true;
    }
    if (foundErrors) {
      set_errors(Object.assign({}, _errors));
      return;
    }
    guideApi.searchIndividual(name_last, badge_number)
      .then((response) => {
        history.push('/guide/update/' + response.data);
      })
      .catch((err) => {
        _errors.page = 'Could not find an individual with entered values. Please try again.';
        set_errors(Object.assign({}, _errors));
      });


  }

  function searchByEmail(e) {
    e.preventDefault();
    let foundErrors = false;
    let _errors = {};
    if (!email) {
      _errors.email = 'This field is required.';
      foundErrors = true;
    }
    if (foundErrors) {
      set_errors(Object.assign({}, _errors));
      return;
    }
    guideApi.searchIndividualByEmail(expo_id, email)
      .then((response) => {
        history.push('/guide/update/' + response.data);
      })
      .catch((err) => {
        _errors.page = 'Could not find an individual with entered values. Please try again.';
        set_errors(Object.assign({}, _errors));
      });
  }

  function onChange(event) {
    const field = event.target.name;
    if (field == 'name_last') {
      set_name_last(event.target.value)
    }
    if (field == 'badge_number') {
      set_badge_number(event.target.value)

    }
    if (field == 'email') {
      set_email(event.target.value)

    }

  }
  if (domain === 'localhost' || domain === 'itaguides.thenexus.net')
    return (
      <div className="jumbotron padding-20" style={{
        margin: '100px auto', width: '400px', maxWidth: '100%'
      }}>
        <h3 className="title">ITA Guides</h3>
        <form>
          {errors && errors.page && <div className="alert alert-danger">{errors.page}</div>}
          <TextInput name="email" label="Email" value={email} placeholder="Email" onChange={onChange} error={errors.email} />
          <button type="button" className="btn btn-air-red btn-wide btn-squared" onClick={searchByEmail}>See My Guide</button>
        </form>
      </div>
    )
  else
    return (
      <div className="jumbotron padding-20" style={{
        margin: '100px auto',
        width: '400px',
        maxWidth: '100%'
      }}>
        <h3 className="title">Nexus Guides</h3>
        <form>
          {errors && errors.page && <div className="alert alert-danger">{errors.page}</div>}
          <TextInput name="badge_number" label="Badge Number" value={badge_number} placeholder="Badge or Registration Number" onChange={onChange} error={errors.badge_number} />
          <TextInput name="name_last" label="Last Name" placeholder="Last Name" value={name_last} onChange={onChange} error={errors.name_last} />
          <button type="button" className="btn btn-air-red btn-wide btn-squared" onClick={searchIndividual}>See My Guide</button>
        </form>
      </div>

    );

}

export default HomePage;
