import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as eventActions from '../../actions/eventActions';
import * as guideActions from '../../actions/guideActions';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Head from '../common/Head';
import PropTypes from 'prop-types';
import { AuthContext } from '../../context/auth'

class MySchedulePage extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      individual: Object.assign({}, this.props.individual),
      errors: {}
    };

  }

  componentDidMount()
  {
    const self = this;
    if (!this.props.individual.id) {
      Promise.resolve(this.props.actions.loadIndividual(this.props.individualId))
      .then(async () => {
        const expo_id = self.props.individual.expo_id;
        await this.context.checkIfMarketplaceClosed(expo_id)
        //this.props.actions.loadEvents(expo_id);
        this.props.actions.loadExpo(expo_id);
      });
    }
  }

  render() {
    const {expo, events, individual} = this.props;

    return (
      <div>
        <Head expo={expo} individual={individual} />
        <div className="row buyers-guide">
          <div className="guide-data-main col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
            <Header individual={individual} expo={expo}/>
            <div>
              {individual.id}
            </div>
            <Footer/>
          </div>
        </div>
      </div>
    );
  }
}

MySchedulePage.propTypes = {
  events: PropTypes.array.isRequired,
  expo: PropTypes.object.isRequired,
  individual: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  individualId: PropTypes.string.isRequired
};

MySchedulePage.contextType = AuthContext;

function mapStateToProps(state, ownProps){
  return {
    individualId: ownProps.match.params.id,
    events: state.events,
    expo: state.expo,
    individual: state.individual
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, eventActions, guideActions), dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MySchedulePage);
