import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "./components/home/HomePage";
import MarketplacePage from "./components/guide/MarketplaceGuidePage";
import ShaderForSenatePage from "./components/guide/ShaderForSenateGuidePage";
import GuidePage from "./components/guide/GuidePage";
import GuideManagePage from "./components/guide/GuideManagePage";
import GuidePrintPage from "./components/guide/GuidePrintPage";
import EventsPage from "./components/events/EventsPage";
import MySchedulePage from "./components/events/MySchedulePage";
import { useTracking } from "./tracking";
import ReactGA from "react-ga";
import { AuthConsumer } from "./context/auth";
import MarketplaceClosedPage from "./components/errors/MarketplaceClosedPage";

ReactGA.initialize("UA-78353111-1");

const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

export default () => {
  useTracking("G-J9GDLJZBJB");
  return (
    <AuthConsumer>
      {({ loading, isAuthenticated, isMarketplaceClosed, expo }) =>
        loading || isAuthenticated || !isMarketplaceClosed ? (
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/marketplace/:id" component={MarketplacePage} />
            <Route exact path="/shaderforsenate" component={ShaderForSenatePage} />
            <Route exact path="/guide/:id" component={GuidePage} />
            <Route exact path="/guide/update/:id" component={GuideManagePage} />
            <Route exact path="/guide/print/:id" component={GuidePrintPage} />
            <Route path="/events/:id" component={EventsPage} />
            <Route path="/schedule/:id" component={MySchedulePage} />
          </Switch>
        ) : (
          <MarketplaceClosedPage expo={expo} />
        )
      }
    </AuthConsumer>
  );
};
