import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { pageview } from './gtag'

const trackPage = (pathname) => {
  // remove uuid-s from pathname
  const page = pathname.replace(/\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/i, "");
  pageview(page);
}

export const useTracking = (trackingId) => {
  const { listen, location } = useHistory()

  useEffect(() => {
    trackPage(location.pathname);
  }, [])

  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return
      trackPage(location.pathname);
    })
    return unlisten
  }, [trackingId, listen])
}
