import React from 'react';
import {Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  expo: PropTypes.object.isRequired
};

const ExpoInfoModal = ({showModal, onClose, expo}) => {
  return (
      <Modal show={showModal} onHide={onClose} bsSize="large">
        <Modal.Header closeButton className="bg-white">
          <Modal.Title>Expo Information</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          {expo.floorplan_url && <div className="margin-bottom-10"><h4 className="inline text-bold">Floor plan map</h4>&nbsp;<a href={expo.floorplan_url} target="_blank">Click Here</a></div>}
          {expo.agenda_url && <div className="margin-bottom-10"><h4 className="inline text-bold">Agenda & Events</h4>&nbsp;<a href={expo.agenda_url} target="_blank">Click Here</a></div>}
          {expo.transportation_url && <div><h4 className="inline text-bold">Transportation</h4>&nbsp;<a href={expo.transportation_url} target="_blank">Click Here</a></div>}
          <hr />
          </Modal.Body>
        <Modal.Footer className="bg-white">
          <button className="btn btn-squared btn-sm btn-wide btn-air-red" onClick={onClose}>Close</button>
        </Modal.Footer>
      </Modal>
  );
};

ExpoInfoModal.propTypes = propTypes;

export default ExpoInfoModal;
