import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'

const propTypes = {
  lines: PropTypes.number,
};

const defaultProps = {
  lines: 3
}

const ContentContainer = styled.span(({ lines, truncate }) => truncate && `
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: ${lines};
-webkit-box-orient: vertical;
text-overflow: ellipsis;
`)

const MoreButton = styled.a(({ truncate }) => `
display: block;
text-align: right;
${truncate ? `
background-image: linear-gradient(to right, rgba(255,255,255,0.001), rgb(255, 255, 255) 80%);
position: absolute;
right: 0;
bottom: 0;
width: 15em;
margin: 0;` : ''}
`)

const ReadMore = ({ lines, children, ...props }) => {
  const [isLongText, setIsLongText] = useState(false)
  const [truncate, setTruncate] = useState(true)
  const content = useRef()
  useEffect(() => {
    // check if content 
    if (content && content.current.scrollHeight > content.current.clientHeight) {
      setIsLongText(true)
    }
  }, [content])

  return (
    <div style={{ position: 'relative' }}>
      <ContentContainer ref={content} lines={lines} truncate={truncate} {...props}>
        {children}
      </ContentContainer>
      {isLongText && <MoreButton truncate={truncate} onClick={() => setTruncate(!truncate)}>{truncate ? 'More...' : 'Less...'}</MoreButton>}
    </div>
  )
}
ReadMore.propTypes = propTypes;
ReadMore.defaultProps = defaultProps;

export default ReadMore;
