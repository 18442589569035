import React, { useState, useEffect, createContext } from "react";
import GuideApi from "../api/guideApi";

let api_base_url = "http://localhost:3000";
if (
  process.env.REACT_APP_NODE_ENV &&
  process.env.REACT_APP_NODE_ENV === "production"
)
  api_base_url = "https://portal.thenexus.net";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMarketplaceClosed, setIsMarketplaceClosed] = useState(false);
  const [expo, setExpo] = useState();

  useEffect(() => {
    if(isMarketplaceClosed) checkIfAuthenticated(expo.id)
  }, [isMarketplaceClosed, expo])

  const checkIfMarketplaceClosed = async (expo_id) => {
    try {
      const result = await GuideApi.getExpo(expo_id);
      const expo = result.data;
      setExpo(expo);
      if (expo.guide_settings?.marketplace_closed) {
        setIsMarketplaceClosed(true);
      }
      else setLoading(false)
    } catch (error) {
      console.error(error);
    }
  };

  const checkIfAuthenticated = async (expo_id) => {
    try {
      await GuideApi.getExpoWithCredentials(expo_id);
      setIsAuthenticated(true);
      setLoading(false)
    } catch (error) {
      setIsAuthenticated(false);
      setLoading(false)
    }
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        isAuthenticated,
        isMarketplaceClosed,
        expo,
        checkIfMarketplaceClosed,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
