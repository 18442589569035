/*eslint-disable import/default */
import axios from 'axios';

let api_base_url = 'http://localhost:3000';
if(process.env.REACT_APP_NODE_ENV && process.env.REACT_APP_NODE_ENV === 'production')  api_base_url = 'https://portal.thenexus.net';

class EventsApi {
  static getAllEvents(id) {
    return new Promise((resolve, reject) => {
      axios.get(`${api_base_url}/public_api/buyers_guide/events/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(err => reject(err));
    });
  }
}

export default EventsApi;
