import React from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import SelectInput from '../common/SelectInput';
import Filters from './Filters';
import Sorter from './Sorting';
import ExpoInfoModal from './ExpoInfoModal';
import GuideTipsModal from './GuideTipsModal';
import PropTypes from 'prop-types';

const propTypes = {
  expo: PropTypes.object.isRequired,
  individual: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  original_filters: PropTypes.object.isRequired,
  onFilterApply: PropTypes.func.isRequired,
  sorterSelection:PropTypes.object.isRequired,
  onSortApply: PropTypes.func.isRequired,
  onShowHideRemovedList: PropTypes.func.isRequired,
  removedListVisible: PropTypes.bool.isRequired,
  showBoothLocation: PropTypes.bool.isRequired
};


class FilterBar extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      individual: {},
      sorterOptions: [
        {
          label: 'Alphabetical',
          value: 'exhibitor_name'
        }, {
          label: 'Booth Number',
          value: 'booth_location_2'
        }, {
          label: 'Interest Level',
          value: 'interest'
        }
      ],
      sorterSelection: Object.assign({}, props.sorterSelection),
      filters: Object.assign({}, props.filters),
      original_filters: Object.assign({}, props.original_filters),
      showSorter: false,
      showFilter: false,
      showExpoInfoModal: false,
      showGuideTipsModal: false,
      dataFiltered: false
    };

    //Filter functions...
    this.showHideFilterOnClick = this.showHideFilterOnClick.bind(this);
    this.filterLocationOnChange = this.filterLocationOnChange.bind(this);
    this.filterInterestOnChange = this.filterInterestOnChange.bind(this);
    this.filterKeywordsOnChange = this.filterKeywordsOnChange.bind(this);
    this.filterCategoriesOnChange = this.filterCategoriesOnChange.bind(this);
    this.filterOnApplyClick = this.filterOnApplyClick.bind(this);
    this.filterOnCancelClick = this.filterOnCancelClick.bind(this);
    this.filterOnResetClick = this.filterOnResetClick.bind(this);
    this.filterOnCheckAllCategories = this.filterOnCheckAllCategories.bind(this);
    this.filterOnCheckAllKeywords = this.filterOnCheckAllKeywords.bind(this);

    //Sorter functions...
    this.showHideSorterOnClick = this.showHideSorterOnClick.bind(this);
    this.sorterOnSortClick = this.sorterOnSortClick.bind(this);
    this.sorterOnCancelClick = this.sorterOnCancelClick.bind(this);
    this.sorterOnChange = this.sorterOnChange.bind(this);

    //Modals...
    this.modalExpoInfoOnClose = this.modalExpoInfoOnClose.bind(this);
    this.modalExpoInfoOnOpen = this.modalExpoInfoOnOpen.bind(this);
    this.modalGuideTipsOnOpen = this.modalGuideTipsOnOpen.bind(this);
    this.modalGuideTipsOnClose = this.modalGuideTipsOnClose.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      original_filters: Object.assign({}, nextProps.original_filters),
      filters: Object.assign({}, nextProps.filters),
      sorterSelection: Object.assign({}, nextProps.sorterSelection),
      dataFiltered: this.filtersOn(nextProps.filters)
    });

  }

  showHideFilterOnClick()
  {
    const {showFilter, showSorter} = this.state;
    this.setState({
      showFilter: !showFilter,
      showSorter: false
    });
  }

  filterLocationOnChange(event)
  {
    let filters = Object.assign({}, this.state.filters);
    let value = event.target.value;
    filters.locations.forEach(loc => {
      if (loc.value === value) {
        loc.selected = event.target.checked;
      }
    });
    this.setState({filters: filters});
  }

  filterInterestOnChange(event)
  {
    let filters = Object.assign({}, this.state.filters);
    let value = event.target.value;
    filters.interestLevels.forEach(interest => {
      if (interest.value === value) {
        interest.selected = event.target.checked;
      }
    });
    this.setState({filters: filters});
  }

  filterKeywordsOnChange(event)
  {
    let filters = Object.assign({}, this.state.filters);
    let value = event.target.value;
    filters.keywords.forEach(keyword => {
      if (keyword.value === value) {
        keyword.selected = event.target.checked;
      }
    });
    this.setState({filters: filters});
  }

  filterCategoriesOnChange(event)
  {
    let filters = Object.assign({}, this.state.filters);
    let value = event.target.value;
    filters.categories.forEach(category => {
      if (category.value === value) {
        category.selected = event.target.checked;
      }
    });
    this.setState({filters: filters});
  }

  filterOnApplyClick(event)
  {
    event.preventDefault();
    const {filters} = this.state;
    const filtered = this.filtersOn(filters);
    this.setState({
      dataFiltered : filtered
    });
    this.props.onFilterApply(filters);
  }

  filtersOn(filters) {
    let filtered = false;
    filters.categories.forEach(item => {
      if (!item.selected)
        filtered = true;
    });

    if (filtered)
      return true;

    filters.keywords.forEach(item => {
      if (!item.selected)
        filtered = true;
    });

    filters.locations.forEach(item => {
      if (!item.selected)
        filtered = true;
    });

    filters.interestLevels.forEach(item => {
      if (!item.selected)
        filtered = true;
    });

    return filtered;
  }

  filterOnResetClick(event)
  {
    event.preventDefault();
    const {original_filters} = this.state;
    this.setState({filters: Object.assign({}, original_filters)});
    this.props.onFilterApply(original_filters);
  }

  filterOnCancelClick(event)
  {
    event.preventDefault();
    const {original_filters} = this.state;

    this.setState({
      showFilter: false
      //filters: Object.assign({}, original_filters)
    });
  }

  filterOnCheckAllCategories(val, event)
  {
    event.preventDefault();
    const {filters} = this.state;
    filters.categories.forEach(item => {
      item.selected = val;
    });
    this.setState({
      filters: Object.assign({}, filters)
    });
  }

  filterOnCheckAllKeywords(val, event)
  {
    event.preventDefault();
    const {filters} = this.state;
    filters.keywords.forEach(item => {
      item.selected = val;
    });
    this.setState({
      filters: Object.assign({}, filters)
    });
  }

  showHideSorterOnClick()
  {
    const {showFilter, showSorter} = this.state;
    this.setState({
      showSorter: !showSorter,
      showFilter: false
    });
  }

  sorterOnSortClick() {
    event.preventDefault();
    const {sorterSelection} = this.state;
    this.props.onSortApply(sorterSelection);
  }

  sorterOnCancelClick() {
    const {showSorter} = this.state;
    this.setState({showSorter: false});
  }

  sorterOnChange(event) {
    let sorterSelection = Object.assign({}, this.state.sorterSelection);
    sorterSelection[event.target.name] = event.target.value;
    this.setState({
      sorterSelection: sorterSelection
    });
  }


  modalExpoInfoOnClose() {
    this.setState({ showExpoInfoModal: false });
  }

  modalExpoInfoOnOpen(){
    this.setState({ showExpoInfoModal: true });
  }

  modalGuideTipsOnClose() {
    this.setState({ showGuideTipsModal: false });
  }

  modalGuideTipsOnOpen(){
    this.setState({ showGuideTipsModal: true });
  }

  render() {
    const {expo, individual, removedListVisible, onShowHideRemovedList, showBoothLocation} = this.props;
    const {showFilter, filters, showSorter, sorterOptions, sorterSelection, showExpoInfoModal, showGuideTipsModal, dataFiltered} = this.state;
    return (
      <div>
        <div style={{backgroundColor: '#cccccc'}} className="guide-data-box">
          <div className="row">
            <div className="col-md-5 col-sm-12 hidden-xs">
              {!dataFiltered && <button
                type="button"
                onClick={this.showHideFilterOnClick}
                className="btn btn-wide btn-sm btn-squared btn-default margin-right-10">
                <span className="glyphicon glyphicon-filter"></span>&nbsp;&nbsp;Filters
              </button>}
              {dataFiltered && <button
                type="button"
                onClick={this.showHideFilterOnClick}
                className="btn btn-wide btn-sm btn-squared btn-air-red margin-right-10">
                <span className="glyphicon glyphicon-filter"></span>&nbsp;&nbsp;Filters (on)
              </button>}

              <button
                type="button"
                onClick={this.showHideSorterOnClick}
                className="btn btn-wide btn-sm btn-squared btn-default">
                <span className="glyphicon glyphicon-sort-by-attributes"></span>&nbsp;&nbsp;Sorting
              </button>
            </div>
            <div className="col-md-7 col-sm-12 hidden-xs filter-bar-action-buttons">
              <button type="button" onClick={this.modalExpoInfoOnOpen} className="btn btn-host btn-squared btn-sm">
                <span className="glyphicon glyphicon-info-sign"></span>&nbsp;&nbsp;Map & Info
              </button>
              <Link style={{maxWidth: '100px'}} to={`/guide/update/${individual.id}`} className="btn btn-air-red btn-squared btn-sm margin-left-5"><span className="glyphicon glyphicon-edit"></span>&nbsp;&nbsp;Edit Guide</Link>
              {/* <button type="button" onClick={this.modalGuideTipsOnOpen} className="btn btn-air-red btn-squared btn-sm margin-left-5" style={{maxWidth: '100px'}}>
                <span className="glyphicon glyphicon-info-sign"></span>&nbsp;&nbsp;Guide Tips
              </button> */}
              <a href={`/guide/print/${individual.id}`} target="_blank" className="btn btn-air-red btn-squared btn-sm hidden-xs margin-left-5" style={{maxWidth: '100px'}}><span className="glyphicon glyphicon-print"></span>&nbsp;&nbsp;Print</a>

              {!removedListVisible && <button type="button" onClick={onShowHideRemovedList} className="btn btn-contrast btn-squared btn-sm margin-left-5" style={{maxWidth: '100px'}}>
                Show Removed
                </button>}
              {removedListVisible && <button type="button" onClick={onShowHideRemovedList} className="btn btn-light-blue btn-squared btn-sm margin-left-5" style={{maxWidth: '100px'}}>
                Show Selected
                </button>}

            </div>
          </div>
          <div className="row">
            <div className="visible-xs col-xs-12">
              {!dataFiltered && <button
                type="button"
                onClick={this.showHideFilterOnClick}
                className="btn btn-sm btn-squared btn-default margin-right-5">
                <span className="glyphicon glyphicon-filter"></span>
              </button>}
              {dataFiltered && <button
                type="button"
                onClick={this.showHideFilterOnClick}
                className="btn btn-sm btn-squared btn-air-red margin-right-5">
                <span className="glyphicon glyphicon-filter"></span>
              </button>}
              <button
                type="button"
                onClick={this.showHideSorterOnClick}
                className="btn btn-sm btn-squared btn-default margin-right-5">
                <span className="glyphicon glyphicon-sort-by-attributes"></span>
              </button>
              <button type="button" onClick={this.modalExpoInfoOnOpen} className="btn btn-host btn-squared btn-sm">
                <span className="glyphicon glyphicon-info-sign"></span>&nbsp;Info
              </button>
              <Link style={{maxWidth: '100px'}} to={`/guide/update/${individual.id}`} className="btn btn-air-red btn-squared btn-sm margin-left-5"><span className="glyphicon glyphicon-edit"></span>&nbsp;&nbsp;Edit</Link>
              <button type="button" onClick={this.modalGuideTipsOnOpen} className="btn btn-air-red btn-squared btn-sm margin-left-5" style={{maxWidth: '100px'}}>
                <span className="glyphicon glyphicon-info-sign"></span>&nbsp;Tips
              </button>
              <a href={`/guide/print/${individual.id}`} target="_blank" className="btn btn-air-red btn-squared btn-sm hidden-xs margin-left-5" style={{maxWidth: '100px'}}><span className="glyphicon glyphicon-print"></span>&nbsp;&nbsp;Print</a>

              {!removedListVisible && <button type="button" onClick={onShowHideRemovedList} className="btn btn-contrast btn-squared btn-sm margin-left-5" style={{maxWidth: '100px'}}>
                Removed
                </button>}
              {removedListVisible && <button type="button" onClick={onShowHideRemovedList} className="btn btn-light-blue btn-squared btn-sm margin-left-5" style={{maxWidth: '100px'}}>
                Selected
                </button>}
            </div>

          </div>
      </div>

    {showFilter &&
      <Filters
        individual={individual}
        filters={filters}
        categoryOnChange={this.filterCategoriesOnChange}
        keywordOnChange={this.filterKeywordsOnChange}
        locationOnChange={this.filterLocationOnChange}
        interestOnChange={this.filterInterestOnChange}
        onApplyClick={this.filterOnApplyClick}
        onCancelClick={this.filterOnCancelClick}
        onResetClick={this.filterOnResetClick}
        oncheckAllCategories={this.filterOnCheckAllCategories}
        oncheckAllKeywords={this.filterOnCheckAllKeywords}
        showBoothLocation={showBoothLocation}
        />
    }
    {showSorter &&
      <Sorter
        individual={individual}
        options={sorterOptions}
        selection={sorterSelection}
        onChange={this.sorterOnChange}
        onSortClick={this.sorterOnSortClick}
        onCancelClick={this.sorterOnCancelClick}
        />
    }
    <ExpoInfoModal showModal={showExpoInfoModal} onClose={this.modalExpoInfoOnClose} expo={expo} />
    <GuideTipsModal expo={expo} showModal={showGuideTipsModal} onClose={this.modalGuideTipsOnClose} />
  </div>
    );
  }
}

FilterBar.propTypes = propTypes;

export default FilterBar;
