import React from 'react';
import {Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

class SendEmailModal extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      content: ''
    };

  }

  render() {
    const {showModal, onClose} = this.props;
    const {errors, content} = this.state;
    return (
        <Modal show={showModal} onHide={onClose} bsSize="large">
          <Modal.Header closeButton className="bg-white">
            <Modal.Title>Send Email</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-white">
            <form>
              {errors && errors.page && <div className="alert alert-danger">{errors.page}</div>}
              <div className="form-group">
                <label htmlFor="content">Description</label>
                <div className="field">
                  <textarea name="content" style={{width:'100%'}} rows="10"></textarea>
                  {errors && errors.content && <div className="alert alert-danger">{errors.content}</div>}
                </div>
              </div>
              <button type="button" className="btn btn-air-red btn-squared" onClick={this.searchIndividual}>Send Email</button>
            </form>
            </Modal.Body>
          <Modal.Footer className="bg-white">
            <button className="btn btn-squared btn-sm btn-wide btn-air-red" onClick={onClose}>Close</button>
          </Modal.Footer>
        </Modal>
    );
  }
}

SendEmailModal.propTypes = propTypes;

export default SendEmailModal;
