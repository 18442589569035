import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function expoReducers(state = initialState.expo, action) {
  switch (action.type) {
      case types.LOAD_EXPO_SUCCESS:
        //localStorage.setItem("EXPO", JSON.stringify(action.expo));
        return action.expo;
    default:
      return state;

  }
}
