/*eslint-disable import/default */
import React from 'react';
import { render } from 'react-dom';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './routes';
import App from './App'

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.css';
import '../node_modules/react-select/dist/react-select.css';
import '../node_modules/rc-tree/assets/index.css';
import './styles/styles.css';
require('./favicon.ico');

const store = configureStore();

render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>, document.getElementById('app'));
