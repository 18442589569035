
import React from 'react';
import { Link } from 'react-router-dom';
import renderHTML from 'react-render-html';
import FeaturedBar from './FeaturedBar';
import PropTypes from 'prop-types';
import ReadMore from '../common/ReadMore'
import { event } from '../../gtag'

const propTypes = {
  expo: PropTypes.object.isRequired,
  exhibitor: PropTypes.object.isRequired,
  setInterestLevel: PropTypes.func.isRequired,
  onWebsiteClick: PropTypes.func.isRequired,
  onSendEmailClick: PropTypes.func.isRequired
};

const ExhibitorItem = ({ expo, exhibitor, setInterestLevel, onWebsiteClick, onSendEmailClick }) => {
  const activeClassName = " btn-active-interest";
  const defaultClassName = " btn-default";
  let interestedClassName = 'btn btn-squared btn-wide btn-sm margin-right-5';
  let maybeClassName = 'btn btn-squared btn-wide btn-sm margin-right-5';
  let notInterestedClassName = 'btn btn-squared btn-wide btn-sm margin-right-5';
  let showBoothLocation = false;
  let showBoothNumber = false;

  if (expo && expo.guide_settings) {
    showBoothLocation = expo.guide_settings.show_booth_location;
    showBoothNumber = expo.guide_settings.show_booth_number;
  }

  interestedClassName = exhibitor.interest === 'interested'
    ? interestedClassName + activeClassName
    : interestedClassName + defaultClassName;
  maybeClassName = exhibitor.interest === 'maybe'
    ? maybeClassName + activeClassName
    : maybeClassName + defaultClassName;
  notInterestedClassName = exhibitor.interest === 'notinterested'
    ? notInterestedClassName + activeClassName
    : notInterestedClassName + defaultClassName;

  let description = '';
  if (exhibitor.exhibitor_description) { description = exhibitor.exhibitor_description.replace(/[<>]/g, '').replace(/(?:\r\n|\r|\n)/g, '<br />'); }

  let exhibitor_photo = exhibitor.company_logo || ('https://s3.amazonaws.com/exposync-uploads/' + expo.expo_default_guide_image_url);

  let mainColor = '#efefef';
  if (expo && expo.guide_settings) {
    mainColor = expo.guide_settings.guide_main_color;
  }
  return (

    <div className="guide-data-box" style={{
      borderLeft: '5px solid',
      borderRight: '5px solid',
      borderColor: mainColor
    }}>
      <div className="row">
        <div className="col-md-10 col-sm-10 col-xs-12">
          <div className="row">
            <div className="col-md-8 col-sm-6 col-xs-7">
              <span className="title">
                {exhibitor.exhibitor_name}
              </span>
              {exhibitor.custom_fields && <FeaturedBar fields={exhibitor.custom_fields} />}
              <div>
                {exhibitor.exhibitor_website && <button type="button" className="btn btn-light-blue btn-xs btn-squared margin-right-5 " onClick={onWebsiteClick.bind(null, exhibitor, exhibitor.exhibitor_website)}>Website
                </button>}
                {exhibitor.guide_email &&
                  <a className="btn btn-air-red btn-xs btn-squared" href={`mailto:${exhibitor.guide_email}?subject=Inquiry from IPPE 2021 Marketplace Visitor`} target="_blank" onClick={() => event({ action: 'email', category: 'engagement', label: 'Email Event' })} >Email</a>}
                {exhibitor.facebook_url && <a href="#" onClick={() => { event({ action: 'facebook', category: 'social_links', label: 'Facebook' }); onWebsiteClick(exhibitor, exhibitor.facebook_url) }} className="margin-left-5"><img height="24px" alt="Facebook" src="https://s3.amazonaws.com/exposync-uploads/fb.png" /></a>}
                {exhibitor.twitter_url && <a href="#" onClick={() => { event({ action: 'twitter', category: 'social_links', label: 'Twitter' }); onWebsiteClick(exhibitor, exhibitor.twitter_url) }} className="margin-left-5"><img height="24px" alt="Twitter" src="https://s3.amazonaws.com/exposync-uploads/twitter.png" /></a>}
                {exhibitor.linkedin_url && <a href="#" onClick={() => { event({ action: 'linkedin', category: 'social_links', label: 'Linkedin' }); onWebsiteClick(exhibitor, exhibitor.linkedin_url) }} className="margin-left-5"><img height="24px" alt="LinkedIn" src="https://s3.amazonaws.com/exposync-uploads/linked-in.png" /></a>}
                {exhibitor.instagram_url && <a href="#" onClick={() => { event({ action: 'instagram', category: 'social_links', label: 'Instagram' }); onWebsiteClick(exhibitor, exhibitor.instagram_url) }} className="margin-left-5"><img height="24px" alt="Instagram" src="https://s3.amazonaws.com/exposync-uploads/instagram.png" /></a>}
                {exhibitor.pinterest_url && <a href="#" onClick={() => { event({ action: 'pinterest', category: 'social_links', label: 'Pinterest' }); onWebsiteClick(exhibitor, exhibitor.pinterest_url) }} className="margin-left-5"><img height="24px" alt="Pinterest" src="https://s3.amazonaws.com/exposync-uploads/pinterest.png" /></a>}
                {exhibitor.googleplus_url && <a href="#" onClick={() => { event({ action: 'google_plus', category: 'social_links', label: 'Google Plus' }); onWebsiteClick(exhibitor, exhibitor.googleplus_url) }} className="margin-left-5"><img height="24px" alt="Google +" src="https://s3.amazonaws.com/exposync-uploads/google-plus.png" /></a>}
                {exhibitor.snapchat_url && <a href="#" onClick={() => { event({ action: 'snapchat', category: 'social_links', label: 'Snapchat' }); onWebsiteClick(exhibitor, exhibitor.snapchat_url) }} className="margin-left-5"><img height="24px" alt="Snapchat" src="https://s3.amazonaws.com/exposync-uploads/snapchat.png" /></a>}
                {exhibitor.youtube_url && <a href="#" onClick={() => { event({ action: 'youtube', category: 'social_links', label: 'YouTube' }); onWebsiteClick(exhibitor, exhibitor.youtube_url) }} className="margin-left-5"><img height="24px" alt="YouTube" src="https://s3.amazonaws.com/exposync-uploads/youtube.png" /></a>}
              </div>
            </div>
            <div className="col-xs-5 hidden-sm hidden-lg hidden-md">
              {exhibitor.exhibitor_website && exhibitor_photo && <a href="#" onClick={onWebsiteClick.bind(null, exhibitor, exhibitor.exhibitor_website)}><img loading="lazy" src={exhibitor_photo} style={{
                maxWidth: '90%'
              }} className="pull-right" /></a>}
              {!exhibitor.exhibitor_website && exhibitor_photo && <img loading="lazy" src={exhibitor_photo} style={{
                maxWidth: '90%'
              }} className="pull-right" />}
            </div>
            <div className="col-md-4 col-xs-12 text-bold pull-right">
              {showBoothLocation && <span>
                {exhibitor.booth_location}
              </span>}
              <span>&nbsp;</span>
              {showBoothNumber && <span>{expo.guide_settings.booth_prefix}
              </span>}
              {showBoothNumber && <span>{exhibitor.booth_number}</span>}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-xs-12">
              <table style={{
                width: '100%',
                borderCollapse: 'collapse'
              }}>
                <tbody>
                  {exhibitor.matched_categories && exhibitor.matched_categories.length > 0 && <tr>
                    <td>
                      <div className="text-bold">Category Match</div>
                      <ReadMore
                        className="text-bricky"
                        style={{
                          verticalAlign: 'top',
                          wordWrap: 'break-word'
                        }}>
                        {exhibitor.matched_categories.join(', ')}
                      </ReadMore>
                    </td>
                  </tr>
                  }
                  {exhibitor.matched_keywords && exhibitor.matched_keywords.length > 0 && <tr>
                    <td>
                      <div className="text-bold">Keyword Match</div>
                      <span style={{
                        verticalAlign: 'top',
                        wordWrap: 'break-word'
                      }}>
                        {exhibitor.matched_keywords.join(', ')}
                      </span>
                    </td>
                  </tr>
                  }
                </tbody>
              </table>
            </div>
            <div className="col-md-8 col-xs-12">
              <ReadMore lines={6} backgroundColor={'#FFFFFF'}>
                {renderHTML(description)}
              </ReadMore>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-md-offset-4">
              <button type="button" style={{ borderWidth: '2px', borderStyle: 'outset' }} onClick={() => { event({ action: 'interested', category: 'engagement', label: 'Interested' }); setInterestLevel(exhibitor, 'interested') }} className={interestedClassName}>I'm interested</button>
              <button type="button" onClick={() => { event({ action: 'maybe', category: 'social_links', label: 'Maybe' }); setInterestLevel(exhibitor, 'maybe') }} className={maybeClassName}>Maybe interested</button>
              <button type="button" onClick={() => { event({ action: 'not_interested', category: 'social_links', label: 'Not Interested' }); setInterestLevel(exhibitor, 'notinterested') }} className={notInterestedClassName}>No (Remove)</button>
            </div>
          </div>
        </div>
        <div className="col-md-2 col-sm-2 hidden-xs" style={{
          margin: '0px',
          padding: '0px',
          marginLeft: '-10px'
        }}>
          {exhibitor.exhibitor_website && exhibitor_photo && <a href="#" onClick={onWebsiteClick.bind(null, exhibitor, exhibitor.exhibitor_website)}><img loading="lazy" width="90%" src={exhibitor_photo} className="img-thumbnail pull-right" /></a>}
          {!exhibitor.exhibitor_website && exhibitor_photo && <img loading="lazy" width="90%" src={exhibitor_photo} className="img-thumbnail pull-right" />}
        </div>
      </div>
    </div>
  );
};

ExhibitorItem.propTypes = propTypes;

export default ExhibitorItem;
