import React from 'react';

const Footer = () => {
  return (
    <div className="guide-data-box">
      <table style={{width: '100%'}}>
        <tbody>
          <tr>
            <td style={{textAlign: 'center'}}>
              <div className="copyright">
                © <span className="text-bold">
                2020 Nexus Data Group, Inc.
              </span>
            </div>
            <a href="https://portal.thenexus.net/public/privacy" className="hidden-print" target="_blank">
              Privacy Policy&nbsp;&nbsp;
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  );
};


export default Footer;
