import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as guideActions from '../../actions/guideActions';
import Header from '../common/Header';
import CategoriesManage from './CategoriesManage';
import Footer from '../common/Footer';
import CategoriesTree from '../common/CategoriesTree';
import { toTreeFormat } from '../common/utils/categories';
import Head from '../common/Head';
import Mustache from 'mustache';
import Spinner from '../common/Spinner';
import PropTypes from 'prop-types';
import { AuthContext } from '../../context/auth'

import ReactGA from 'react-ga';
ReactGA.initialize('UA-78353111-1');

const CreateGuideButton = ({ loading, context }) => (
  <div className="col-md-offset-4 col-md-4">
    <button className="btn btn-air-red btn-wide btn-squared" style={{
      width: '100%'
    }} onClick={context.onUpdateIndividual} disabled={loading}>
      {loading ? <div style={{ maxHeight: 22, fontSize: 6 }}><Spinner /> </div>
        : <span className="text-large">
          Create My Guide
        </span>}
    </button>
  </div>
)

class GuideManagePage extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      expo: Object.assign({}, this.props.expo),
      individual: Object.assign({}, this.props.individual),
      errors: {},
      categories: [],
      checkedKeys: [],
      expandedKeys: [],
      keywords: {
        keyword_0: '',
        keyword_1: '',
        keyword_2: '',
        keyword_3: '',
        keyword_4: '',
        keyword_5: '',
        keyword_6: '',
        keyword_7: '',
        keyword_8: '',
        keyword_9: ''
      },
      loading: true,
      createGuideLoading: false
    };
    this.onSelect = this.onSelect.bind(this);
    this.onCheck = this.onCheck.bind(this);
    this.onKeywordsChange = this.onKeywordsChange.bind(this);
    this.onUpdateIndividual = this.onUpdateIndividual.bind(this);
    this.onExpand = this.onExpand.bind(this);

  }

  componentDidMount() {

    if (!this.props.individual.id) {
      Promise.resolve(this.props.actions.loadIndividual(this.props.individualId, this.props.synced))
        .then(async () => {
          const expo_id = this.props.individual.expo_id;
          await this.context.checkIfMarketplaceClosed(expo_id)
          this.props.actions.loadExhibitors(this.props.individual);
          this.props.actions.loadExpo(expo_id);
          //keywords
          let keywords = Object.assign({}, this.state.keywords);
          this.props.individual.keywords.forEach((v, i) => {
            keywords[`keyword_${i}`] = v;
          });
          this.setState({ // eslint-disable-line react/no-did-mount-set-state
            keywords: Object.assign({}, keywords)
          });
        });
    }
    else {
      let keywords = Object.assign({}, this.state.keywords);
      this.props.individual.keywords.forEach((v, i) => {
        keywords[`keyword_${i}`] = v;
      });


      this.setState({// eslint-disable-line react/no-did-mount-set-state
        keywords: Object.assign({}, keywords),
        categories: [...this.props.expo.categories],
        checkedKeys: [...this.props.individual.categories],
        expandedKeys: [...this.props.individual.categories],
        loading: false
      });
    }
  }

  loadNestedChildren(checked, expo_categories) {
    // If a parent is checked, check all it's children during first load
    let res = []
    checked.forEach(checked_key => {
      res = [...new Set([...res ,...expo_categories.filter(x => x.startsWith(checked_key))])]
    })
    this.setState({
      loading: false,
      checkedKeys: [...res]
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.expo && nextProps.expo.id) {
      this.setState({
        categories: [...nextProps.expo.categories],
        checkedKeys: [...nextProps.individual.categories],
        expandedKeys: [...this.props.individual.categories]
      });
      if (nextProps.individual.categories && nextProps.expo.categories) this.loadNestedChildren(nextProps.individual.categories, nextProps.expo.categories)
    }
  }
  componentWillUpdate(nextProps, nextState) { }

  componentDidUpdate(prevProps, prevState) { }

  componentWillUnmount() { }

  onSelect(selectedKeys, info) {

    const self = this;
    let { checkedKeys, categories } = this.state;
    const key = info.node.props.eventKey;
    let newChanges = [];
    newChanges.push(key);
    if (info.node.props.children && info.node.props.children.length > 0) {
      info.node.props.children.forEach(function (item) {
        newChanges.push(item.key);
      });
    }

    newChanges.forEach(function (key) {
      if (checkedKeys.indexOf(key) > -1) {
        checkedKeys = [...checkedKeys.filter(item => item !== key)];
      } else {
        checkedKeys = [...checkedKeys, key];
      }
    });

    self.setState({
      checkedKeys: [...checkedKeys],
      expandedKeys: [...checkedKeys]
    });
  }

  onExpand(expandedKeys) {
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  }


  onCheck(checkedKeys, event) {
    const { expandedKeys } = this.state;
    let newExpandedKeys = [];
    if (event.node.props.children) {
      newExpandedKeys.push(event.node.props.eventKey);
    }
    this.setState({ checkedKeys: [...checkedKeys], expandedKeys: [...expandedKeys, ...newExpandedKeys] });
  }

  onKeywordsChange(event) {
    let keywords = Object.assign({}, this.state.keywords);
    keywords[event.target.name] = event.target.value;
    this.setState({ keywords: Object.assign({}, keywords) });

  }

  onUpdateIndividual(event) {
    this.setState({ createGuideLoading: true }, () => {
      event.preventDefault();
      const { checkedKeys, keywords } = this.state;
      const { individual } = this.props;
      let keywordsArray = [];

      Object.keys(keywords).forEach(key => {
        if (keywords[key])
          keywordsArray.push(keywords[key]);
      });
      let data = {};
      data.categories = checkedKeys;
      data.keywords = keywordsArray;
      data.guide_preferences = {};
      data.guide_created = true;  //Update key to show that guide has been created for this individual.
      if (checkedKeys.length == 0) {
        let errors = {};
        errors.category_length_validation = 'Please select at least one category to create your Personal Guide.';
        //errors....
        this.setState({
          errors: Object.assign({}, errors),
          createGuideLoading: false
        }
        );
        return;
      }

      Promise.resolve(this.props.actions.updateIndividual(individual.id, data))
        .then(() => {
          ReactGA.event({ category: 'Personal Guide', action: 'create_guide' });
          this.setState({ createGuideLoading: false })
          this.props.history.push(`/guide/${individual.id}`);
        });
    })
  }

  render() {
    const { categories, checkedKeys, expandedKeys, keywords, errors, loading, createGuideLoading } = this.state;
    const { expo, individual } = this.props;

    function createIntroMarkup(expo) {
      if (expo && expo.guide_settings) {
        return { __html: Mustache.render(expo.guide_settings.guide_intro, expo) };
      }
    }

    return (
      <div className="row buyers-guide">
        <Head expo={expo} individual={individual} />
        <div className="guide-data-main col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
          <Header individual={individual} expo={expo} displayHeaderMessaging={false} />
          {loading ? <div className="guide-data-box"><Spinner /></div>
            : <div>
              <div className="guide-data-box">
                <div className="col-md-offset-2 col-md-8">
                  <span style={{ textAlign: 'center', fontSize: '15px' }} dangerouslySetInnerHTML={createIntroMarkup(expo)}>
                  </span>
                </div>
                <CreateGuideButton context={this} loading={createGuideLoading} />
              </div>
              <CategoriesManage individual={individual}
                onSelect={this.onSelect}
                onExpand={this.onExpand}
                onCheck={this.onCheck}
                categories={toTreeFormat(categories)}
                checkedKeys={checkedKeys}
                expandedKeys={expandedKeys}
                keywords={keywords}
                onKeywordsChange={this.onKeywordsChange}
                errors={errors} />
              <div className="guide-data-box">
                <CreateGuideButton context={this} loading={createGuideLoading} />
              </div>
            </div>}
          <Footer />
        </div>
      </div>
    );
  }
}
GuideManagePage.propTypes = {
  individualId: PropTypes.string.isRequired,
  exhibitors: PropTypes.array,
  expo: PropTypes.object.isRequired,
  individual: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  synced: PropTypes.bool.isRequired
};

GuideManagePage.contextType = AuthContext;

function mapStateToProps(state, ownProps) {
  return {
    individualId: ownProps.match.params.id,
    exhibitors: state.exhibitors,
    expo: state.expo,
    individual: state.individual,
    synced: state.synced
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, guideActions), dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GuideManagePage);
