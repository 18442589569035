import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function exhibitorsReducers(state = initialState.exhibitors, action) {
  switch (action.type) {
    case types.LOAD_EXHIBITORS_SUCCESS:
      return action.exhibitors;
    case types.UPDATE_EXHIBITOR_SUCCESS:
        return [...state.filter(exhibitor => exhibitor.id !== action.exhibitor.id), Object.assign({}, action.exhibitor)];
    default:
      return state;

  }
}
