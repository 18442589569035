import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function individualReducers(state = initialState.individual, action) {
  switch (action.type) {
      case types.LOAD_INDIVIDUAL_SUCCESS:
        return action.individual;
    default:
      return state;

  }
}
