import * as types from './actionTypes';
import guideApi from '../api/guideApi';
import {beginAjaxCall} from './ajaxStatusActions';

export function loadExhibitorsSuccess(exhibitors) {
  return {
    type: types.LOAD_EXHIBITORS_SUCCESS,
    exhibitors
  };
}

export function loadIndividualSuccess(individual) {
  return {
    type: types.LOAD_INDIVIDUAL_SUCCESS,
    individual
  };
}

export function loadExpoSuccess(expo) {
  return {
    type: types.LOAD_EXPO_SUCCESS,
    expo
  };
}

export function updateExhibitorSuccess(exhibitor) {
  return { type: types.UPDATE_EXHIBITOR_SUCCESS, exhibitor};
}

export function storeSyncedUpdate(synced) {
  return {
    type: types.STORE_SYNCED,
    synced
  };
}

function padLeft(nr, n, str){
    return Array(n-String(nr).length+1).join(str||'0')+nr;
}

export function loadExhibitors(individual) {
  return function(dispatch) {

    return guideApi.getSelectedExhibitors(individual.id)
      .then(response => {
        let exhibitors = response.data.map(exhibitor => {
          exhibitor.interest = setInitialInterest(individual, exhibitor.id);
          let exhibitor_booth_number = exhibitor.booth_number || '';
          let exhibitor_booth_location = exhibitor.booth_location || '';
          if (exhibitor_booth_number.length < 10) {
            exhibitor.booth_location_2 = exhibitor_booth_location + ':' + padLeft(exhibitor_booth_number, 10);
          }
          else
          {
            exhibitor.booth_location_2 = exhibitor_booth_location + ':' + exhibitor_booth_number;
          }
          return exhibitor;
        });
        // localStorage.setItem("EXHIBITORS", JSON.stringify(exhibitors));
        dispatch(loadExhibitorsSuccess(exhibitors));
      })
      .catch(error => {
        let exhibitors = JSON.parse(localStorage.getItem("EXHIBITORS"));
        if (exhibitors && exhibitors.length > 0){
          dispatch(loadExhibitorsSuccess(exhibitors));
        }
        //throw (error);
      });
  };
}

export function loadIndividual(id, synced) {

  return function(dispatch) {
    if (synced == true) {
      let individual = JSON.parse(localStorage.getItem('INDIVIDUAL'));
      if (individual.id == id) {
          dispatch(loadIndividualSuccess(individual));
      }
      else {
        return guideApi.getIndividual(id)
          .then(response => {
            clearStorage();
            let guide_preferences = response.data.guide_preferences;
            delete response.data.guide_preferences;
            localStorage.setItem("INDIVIDUAL", JSON.stringify(response.data));

            dispatch(loadIndividualSuccess(response.data));
            dispatch(storeSyncedUpdate(true));
            return Promise.resolve(guide_preferences);
          })
          .catch(error => {
            let individual = JSON.parse(localStorage.getItem("INDIVIDUAL"));
            if (individual && individual.id == id){
              dispatch(loadIndividualSuccess(individual));
            }
          });
      }
    }
    else {
      return guideApi.getIndividual(id)
        .then(response => {
          clearStorage();
          let guide_preferences = response.data.guide_preferences;
          delete response.data.guide_preferences;
          localStorage.setItem("INDIVIDUAL", JSON.stringify(response.data));

          dispatch(loadIndividualSuccess(response.data));
          dispatch(storeSyncedUpdate(true));
          return Promise.resolve(guide_preferences);
        })
        .catch(error => {
          let individual = JSON.parse(localStorage.getItem("INDIVIDUAL"));
          if (individual && individual.id == id)
            {
              dispatch(loadIndividualSuccess(individual));
            }
        });
    }

  };
}

export function loadExpo(id) {
  return function(dispatch) {
    return guideApi.getExpo(id).then(response => {
      localStorage.setItem("EXPO", JSON.stringify(response.data));
      dispatch(loadExpoSuccess(response.data));
    }).catch(error => {
      let expo = JSON.parse(localStorage.getItem("EXPO"));
      if (expo && expo.id == id)
        {
          dispatch(loadExpoSuccess(expo));
        }
    });
  };
}

export function updateIndividualPreferences(id, data) {

  return function(dispatch) {

    return guideApi.updateIndividual(id, data)
      .then((response) => {
        Promise.resolve(response);
      })
      .catch();
  };
}

export function updateIndividual(id, data, exhibitor) {
  return function(dispatch) {
    const individual = Object.assign({},JSON.parse(localStorage.getItem('INDIVIDUAL')));
    Object.keys(data).forEach(key => {
      if (data[key])
        individual[key] = data[key];
    });
    localStorage.setItem("INDIVIDUAL", JSON.stringify(individual));
    dispatch(storeSyncedUpdate(false));

    if (exhibitor) {
      dispatch(updateExhibitorSuccess(exhibitor));
    }

    return syncIndividual(id).then(() => {

      dispatch(loadIndividualSuccess(individual));
      dispatch(storeSyncedUpdate(true));

      // return guideApi.getSelectedExhibitors(individual.id)
      //   .then(response => {
      //     let exhibitors = response.data.map(exhibitor => {
      //       exhibitor.interest = setInitialInterest(individual, exhibitor.id);
      //       let exhibitor_booth_number = exhibitor.booth_number || '';
      //       let exhibitor_booth_location = exhibitor.booth_location || '';
      //       if (exhibitor_booth_number.length < 10) {
      //         exhibitor.booth_location_2 = exhibitor_booth_location + ':' + padLeft(exhibitor_booth_number, 10);
      //       }
      //       else
      //       {
      //         exhibitor.booth_location_2 = exhibitor_booth_location + ':' + exhibitor_booth_number;
      //       }
      //       return exhibitor;
      //     });
      //     localStorage.setItem("EXHIBITORS", JSON.stringify(exhibitors));
      //     dispatch(loadExhibitorsSuccess(exhibitors));
      //   })
      //   .catch(error => {
      //     let exhibitors = JSON.parse(localStorage.getItem("EXHIBITORS"));
      //     if (exhibitors && exhibitors.length > 0)
      //       dispatch(loadExhibitorsSuccess(exhibitors));
      //     throw (error);
      //   });
    })
    .catch(err => {

    });
  };
}

function syncIndividual(id)
{
  return new Promise((resolve, reject) => {
    const individual = JSON.parse(localStorage.getItem('INDIVIDUAL'));
    if (individual.id == id) {
      let data = {};
      data.keywords = [...individual.keywords];
      data.categories = [...individual.categories];
      data.exhibitors_interested = [...individual.exhibitors_interested];
      data.exhibitors_maybe = [...individual.exhibitors_maybe];
      data.exhibitors_notinterested = [...individual.exhibitors_notinterested];
      data.exhibitors_clicked = [...individual.exhibitors_clicked];
      data.events_registered = [...individual.events_registered];
      data.guide_preferences = Object.assign({}, individual.guide_preferences);
      data.guide_created = individual.guide_created || true;

      return guideApi.updateIndividual(individual.id, data)
        .then((response) => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    }
  });
}

function clearStorage()
{
  localStorage.removeItem('EXHIBITORS');
  localStorage.removeItem('INDIVIDUAL');
  localStorage.removeItem('EXPO');
}

function setInitialInterest(individual, id) {
  if (individual.exhibitors_interested.indexOf(id) > -1)
    return 'interested';
  if (individual.exhibitors_maybe.indexOf(id) > -1)
    return 'maybe';
  if (individual.exhibitors_notinterested.indexOf(id) > -1)
    return 'notinterested';
  return 'w_none';
}
