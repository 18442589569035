import React from  'react';
import PropTypes from 'prop-types';
import EventItem from './EventItem';
import Filters from './Filters';
import Select from 'react-select';
import moment from 'moment';

const propTypes = {
  expo: PropTypes.object.isRequired,
  individual: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired,
  onRegisterEvent: PropTypes.func.isRequired,
  onUnregisterEvent: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  sortedBy: PropTypes.string.isRequired,
  showFilters: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  onFilterTrackChange: PropTypes.func.isRequired,
  onFilterCancelClick: PropTypes.func.isRequired,
  onFilterCheckAllTracks: PropTypes.func.isRequired,
  filtered: PropTypes.bool.isRequired,
  toggleEventDocumentsModal: PropTypes.func.isRequired
};

const EventList = ({expo, individual, events, onRegisterEvent, onUnregisterEvent,
  sortedBy, onSortingChange, showFilters, filters, onFilterTrackChange, onFilterCancelClick, onFilterCheckAllTracks, filtered, toggleEventDocumentsModal}) => {

  const all_dates = events.map(e => moment(e.event_start).format('MM/DD/YYYY'));
  const dates = [];
  all_dates.forEach(x => { if (!(dates.indexOf(x) > -1)) dates.push(x); });
  let options = [
        { value: 'event_start', label: 'Start Time' },
        { value: 'event_track', label: 'Track' },
        { value: 'event_name', label: 'Alphabetical' }
      ];
  const backgroundColor = expo.guide_settings && expo.guide_settings.events_main_color ? expo.guide_settings.events_main_color : '#9bb2bf'
  return (
      <div>
        <div style={{backgroundColor, marginBottom: '20px', padding: '15px 15px 10px 15px', margin: '0px 0 10px 0', minHeight: '62px'}} className="hidden-print">
          <div className="col-md-6 col-xs-12 padding-left-0 padding-right-0">
            <span className="title text-white">
              {`Events (${events.filter(e => !e.event_suppressed).length})`}
            </span>
          </div>
          <div className="col-md-6 col-xs-12 hidden-xs">
            <div className="row">
                <div className="col-md-2 col-xs-2" style={{paddingTop: '10px', paddingRight:'0px', textAlign: 'right', color:'#FFF', fontSize: '1.1em', fontWeight:'bold'}}>
                  <span>Sort By:</span>
                </div>
                <div className="col-md-6 col-xs-6">
                  <Select
                      name="form-field-name"
                      value={sortedBy}
                      options={options}
                      onChange={onSortingChange}
                      clearable={false} />
                </div>
                <div className="col-md-4 col-xs-4">
                    {!filtered && <button type="button" className="btn btn-default btn-wide btn-sm btn-squared" onClick={onFilterCancelClick}><span className="glyphicon glyphicon-filter"></span>&nbsp;&nbsp;Filters</button>}
                    {filtered && <button type="button" className="btn btn-wide btn-sm btn-squared btn-air-red" onClick={onFilterCancelClick}><span className="text-bold glyphicon glyphicon-filter"></span>&nbsp;&nbsp;Filters (on)</button>}
                </div>
            </div>
          </div>
        </div>
        {showFilters && <Filters
                          individual={individual}
                          filters={filters}
                          trackOnChange={onFilterTrackChange}
                          onCancelClick={onFilterCancelClick}
                          onResetClick={onFilterCancelClick}
                          oncheckAllTracks={onFilterCheckAllTracks}
          />}
        <div style={{marginTop: '-10px'}}>
          {events.map((e, index) =>
                  !e.event_suppressed && 
                  <EventItem
                    key={e.id}
                    expo={expo}
                    event={e}
                    individual={individual}
                    onRegisterEvent={onRegisterEvent}
                    onUnregisterEvent={onUnregisterEvent}
                    borderColor={backgroundColor}
                    index={index} 
                    toggleEventDocumentsModal={toggleEventDocumentsModal}
                    />
                )}

        </div>
      </div>
  );
};

EventList.propTypes = propTypes;

export default EventList;
