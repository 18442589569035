import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Time from 'react-time';
import ReadMore from '../common/ReadMore'
import renderHTML from 'react-render-html';
import { event as gtagEvent } from '../../gtag';
import sanitizeHtml from 'sanitize-html';

const propTypes = {
  expo: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  individual: PropTypes.object.isRequired,
  onRegisterEvent: PropTypes.func.isRequired,
  onUnregisterEvent: PropTypes.func.isRequired,
  index: PropTypes.number,
  toggleEventDocumentsModal: PropTypes.func.isRequired
};

const EventItem = ({ expo, event, individual, onRegisterEvent, onUnregisterEvent, borderColor, index, toggleEventDocumentsModal }) => {
  const registered = individual.events_registered.indexOf(event.event_id) > -1;
  const event_photo = event.event_logo_url ? event.event_logo_url : ('https://s3.amazonaws.com/exposync-uploads/' + expo.expo_default_guide_image_url);

  let event_type = 'register'
  if (event.event_view_link) event_type = 'view'
  if (event.event_purchase_link) event_type = 'paid'

  let description = "";
  if (event.event_description) {
    description = sanitizeHtml(event.event_description, {
      allowedAttributes: false,
    }).replace(/(?:\r\n|\r|\n)/g, "<br />");
  }

  const RegisterButton = () => {
    switch (event_type) {
      case 'register': return registered ? <div><span style={{ fontSize: '1.1em', border: '1px solid green', padding: '8px 4px', fontWeight: 'bold' }}>You're registered.</span> <span style={{ fontSize: '1.1em' }}>To cancel registration click </span><a href="#" onClick={() => { gtagEvent({ action: 'register_event', category: 'engagement', label: 'Unregister Event'}); onUnregisterEvent(event) }}><span style={{ fontSize: '1.1em' }}>cancel</span></a></div>
        : <button type="button" className="btn btn-squared btn-wide btn-sm btn-host" onClick={() => { gtagEvent({ action: 'register_event', category: 'engagement', label: 'Register Event'}); register() }}>Register Now!</button>;
      case 'view': return <a onClick={() => { gtagEvent({ action: 'register_event', category: 'engagement', label: 'View Event' }); register() }} className="btn btn-squared btn-wide btn-sm btn-host" target="_blank" href={event.event_view_link}>Watch Now</a>;
      case 'paid': return <a onClick={() => { gtagEvent({ action: 'register_event', category: 'engagement', label: 'Purchase Event'}); register() }} className="btn btn-squared btn-wide btn-sm btn-host" target="_blank" href={event.event_purchase_link}>Purchase Now</a>;
      default: return <></>;
    }
  }

  const register = () => {
    if (!registered) onRegisterEvent(event)
  }

  return (
    <div>

      <div className="guide-data-box" style={{
        borderLeft: `5px solid ${borderColor}`,
        borderRight: `5px solid ${borderColor}`
      }}>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <span className="event_title">
              {event.event_name}
            </span>
          </div>
          <div className="col-md-4 col-xs-12">
            <div className="row">
              <div className="col-md-12">
                {event.event_documents && event.event_documents.length > 0 && <button onClick={() => { gtagEvent({ action: 'download_view_links', category: 'engagement', label: 'Download Event Documents' }); toggleEventDocumentsModal(event.event_documents) }} className="btn btn-light-blue btn-xs btn-squared margin-right-5">Download Info</button>}
                {event.event_email && <a className="btn btn-air-red btn-xs btn-squared margin-right-5" onClick={() => gtagEvent({ action: 'email', category: 'engagement', label: 'Email Event' })} href={`mailto:${event.event_email}?subject=Inquiry from IPPE 2021 Marketplace Visitor`  } target="_blank">Email</a>}
              </div>
            </div>
            <div>
              {event.event_start && <><span className="text-bold">When: </span><span><Time value={event.event_start.substring(0, event.event_start.length - 5)} format="MM/DD/YYYY h:mm a" />{event.event_end && <>&nbsp;-&nbsp;<Time value={event.event_end.substring(0, event.event_end.length - 5)} format="MM/DD/YYYY h:mm a" /></>}</span></>}
            </div>
            <div>
              {event.event_location && <><span className="text-bold">Location: </span><span>{event.event_location}&nbsp;</span><br /></>}
              {event.event_room && <><span className="text-bold">Room: </span><span>{event.event_room}&nbsp;</span><br /></>}
              {event.event_track && <><span className="text-bold">Track: </span><span>{event.event_track}</span><br /></>}
            </div>
            <div>
              {event.event_platform && <><span className="text-bold">Platform: </span><span>{event.event_platform}&nbsp;</span><br /></>}
              {event.event_sponsors && event.event_sponsors.length > 0 && <><span className="text-bold">Sponsors: </span><span>{event.event_sponsors.join(', ')}&nbsp;</span><br /></>}
              {event.event_presenters && <><span className="text-bold">Presenters: </span><span>{event.event_presenters}&nbsp;</span><br /></>}
            </div>
          </div>
          <div className="col-md-6 col-xs-9" style={{ verticalAlign: 'top' }}>
            <div className="event_description">
              <ReadMore lines={6}>
                {renderHTML(description)}
              </ReadMore>
            </div>
          </div>
          <div className="col-md-2 col-xs-3" style={{ verticalAlign: 'top' }}>
            <img loading="lazy" src={event_photo} style={{ maxWidth: '90%' }} className="pull-right" />
          </div>
        </div>
        {event.registration_enabled && <div className="row margin-top-10 margin-bottom-10">
          <div className="col-md-12">
            <RegisterButton />
          </div>
        </div>}
      </div>
    </div>
  );
};

EventItem.propTypes = propTypes;

export default EventItem;
