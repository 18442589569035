import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  individual: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  categoryOnChange: PropTypes.func.isRequired,
  keywordOnChange: PropTypes.func.isRequired,
  locationOnChange: PropTypes.func.isRequired,
  interestOnChange: PropTypes.func.isRequired,
  onApplyClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onResetClick: PropTypes.func.isRequired,
  oncheckAllCategories: PropTypes.func.isRequired,
  oncheckAllKeywords: PropTypes.func.isRequired,
  showBoothLocation: PropTypes.bool
};

const Filters = ({individual, filters, categoryOnChange, keywordOnChange, locationOnChange, interestOnChange, onApplyClick, onCancelClick, onResetClick, oncheckAllCategories, oncheckAllKeywords, showBoothLocation}) => {
  return (
    <div className="guide-data-box" style={{marginTop: '-10px', maxHeight:'100%', overflowY:'auto'}}>
      <div className="col-md-4">
        <h4 className="text-bold">Categories</h4>
          <a href="#" className="btn btn-default btn-xs btn-squared" onClick={oncheckAllCategories.bind(null, true)}>Check All</a>&nbsp;&nbsp;&nbsp;<a href="#" className="btn btn-default btn-xs btn-squared" onClick={oncheckAllCategories.bind(null, false)}>Uncheck All</a>
          <div style={{maxHeight: '200px', overflowY:'scroll'}}>
            {
              filters.categories.map((category, idx) => {
              return (
                <div key={`filter_category_${idx}`} className="checkbox clip-check">
                  <input type="checkbox" id={`filter_category_${idx}`} value={category.value} checked={category.selected} onChange={categoryOnChange}/>
                  <label htmlFor={`filter_category_${idx}`}>
                    <span>{category.text}</span>
                  </label>
                </div>
              );
            })
          }
        </div>
      </div>
      <div className="col-md-4">
        <h4 className="text-bold">Keywords</h4>
          <a href="#" className="btn btn-default btn-xs btn-squared" onClick={oncheckAllKeywords.bind(null, true)}>Check All</a>&nbsp;&nbsp;&nbsp;<a href="#" className="btn btn-default btn-xs btn-squared" onClick={oncheckAllKeywords.bind(null, false)}>Uncheck All</a>
          {
            filters.keywords.map((keyword, idx) => {
            return (
              <div key={`filter_keyword_${idx}`} className="checkbox clip-check">
                <input type="checkbox" id={`filter_keyword_${idx}`} value={keyword.value} checked={keyword.selected} onChange={keywordOnChange}/>
                <label htmlFor={`filter_keyword_${idx}`}>
                  <span>{keyword.text}</span>
                </label>
              </div>
            );
          })
        }
      </div>
      <div className="col-md-2">
        <h4 className="text-bold">Interest Level</h4>
        {
          filters.interestLevels.map((interest, idx) => {
          return (
            <div key={`filter_interested_${idx}`} className="checkbox clip-check">
              <input type="checkbox" id={`filter_interested_${idx}`} value={interest.value} checked={interest.selected} onChange={interestOnChange}/>
              <label htmlFor={`filter_interested_${idx}`}>
                <span>{interest.text}</span>
              </label>
            </div>
          );
        })
      }
      </div>
      {showBoothLocation && <div className="col-md-2">
        <h4 className="text-bold">Booth Location</h4>
        {
          filters.locations.map((location, idx) => {
          return (
            <div key={`filter_location_${idx}`} className="checkbox clip-check">
              <input type="checkbox" id={`filter_location_${idx}`} value={location.value} checked={location.selected} onChange={locationOnChange}/>
              <label htmlFor={`filter_location_${idx}`}>
                <span>{location.text}</span>
              </label>
            </div>
          );
        })
      }
      </div>}
      <div className="col-md-12">
        <button type="button" className="btn btn-squared btn-air-red btn-wide btn-sm" onClick={onApplyClick}>Apply Filters</button>
        <button type="button" className="btn btn-default btn-wide btn-sm btn-squared margin-left-10" onClick={onCancelClick}>Close</button>
      </div>
    </div>
  );
};


Filters.propTypes = propTypes;

export default Filters;
