import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Mustache from "mustache";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";
import { loadScript } from '../../util/scripts'
import { event as gtagEvent } from '../../gtag';

const propTypes = {
  individual: PropTypes.object.isRequired,
  expo: PropTypes.object.isRequired,
  eventsPage: PropTypes.bool,
};
const Header = ({
  individual,
  expo,
  eventsPage,
  exhibitorsPage,
  displayHeaderMessaging = true,
}) => {
  let guide_for = "...";
  let events_for = "...";
  if (expo && expo.guide_settings) {
    guide_for = Mustache.render(expo.guide_settings.guide_for, individual);
    if (expo.guide_settings.events_for)
      events_for = Mustache.render(expo.guide_settings.events_for, individual);
  }
  const individualHasCategories =
    individual.categories && individual.categories.length > 0;
  const [showTranslate, setShowTranslate] = useState(false);
  const [lang, setLang] = useState("en");

  useEffect(() => {
    loadScript(
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit",
      () => {
        // callback after script is loaded
      }
    );
  }, []);

  const onTranslate = () => {
    if (!showTranslate) {
      gtagEvent({ action: 'translate', category: 'engagement', label: 'Translate Event' });
    }
    setShowTranslate(!showTranslate);
  };

  return (
    <div className="guide-data-box padding-top-20 padding-right-5">
      <div className="row">
        <div className="col-sm-12 col-xs-12 col-md-offset-2 col-md-8">
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <a target="_blank" href={expo.website_url}>
                    {expo && expo.expo_logo_url && (
                      <img
                        style={{ maxHeight: "100px", maxWidth: "100%" }}
                        src={`https://s3.amazonaws.com/exposync-uploads/${expo.expo_logo_url}`}
                      />
                    )}
                  </a>
                </td>
              </tr>
              {displayHeaderMessaging && (
                <tr>
                  <td
                    style={{ textAlign: "center" }}
                    className="padding-top-10"
                  >
                    {!eventsPage && <span>{renderHTML(guide_for)}</span>}
                    {eventsPage && <span>{renderHTML(events_for)}</span>}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6" style={{ display: "flex", alignItems: 'center' }}>
          <div
            style={{ display: "flex", justifyContent: "center", alignItems: "center", border: '1px solid #efefef' }}
            className="btn btn-wide btn-squared btn-sm margin-right-5"
            onClick={() => onTranslate()}
          >
            <span className="margin-right-5">Translate</span>
            <i className="fa fa-globe" style={{fontSize: 14}}/>
            {/* <span> ({lang.toUpperCase()})</span> */}
          </div>

          <div
            id="google_translate_element"
            style={{ display: showTranslate ? "block" : "none" }}
          />
        </div>
        <div className="col-md-6" style={{ textAlign: "right" }}>
          {eventsPage && (
            <Link
              to={`/guide/${!individualHasCategories ? "update/" : ""}${
                individual.id
              }`}
              className="btn btn-wide btn-air-red btn-squared btn-sm pull-right"
              style={{ minWidth: "180px" }}
            >
              View Exhibitors
            </Link>
          )}
          {exhibitorsPage &&
            expo &&
            expo.guide_settings &&
            expo.guide_settings.show_events && (
              <Link
                to={`/events/${individual.id}`}
                className="btn btn-wide btn-air-red btn-squared btn-sm pull-right"
                style={{ minWidth: "180px" }}
              >
                View Events
              </Link>
            )}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = propTypes;

export default Header;
