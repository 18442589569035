import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  individual: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  trackOnChange: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onResetClick: PropTypes.func.isRequired,
  oncheckAllTracks: PropTypes.func.isRequired
};

const Filters = ({individual, filters, trackOnChange, onCancelClick, onResetClick, oncheckAllTracks}) => {
  return (
    <div className="guide-data-box" style={{marginTop: '-10px', maxHeight:'100%', overflowY:'auto', borderLeft: '1px solid #6f6f6f', borderRight: '1px solid #6f6f6f', backgroundColor:'#f5f5f5'}}>
      <div className="col-md-8 col-xs-12">
        <h4 className="text-bold">Tracks</h4>
          <a href="#" className="btn btn-default btn-xs btn-squared" onClick={oncheckAllTracks.bind(null, true)}>Check All</a>&nbsp;&nbsp;&nbsp;<a href="#" className="btn btn-default btn-xs btn-squared" onClick={oncheckAllTracks.bind(null, false)}>Uncheck All</a>
          <div style={{maxHeight: '200px', overflowY:'scroll'}}>
            {
              filters.tracks.map((track, idx) => {
              return (
                <div key={`filter_category_${idx}`} className="checkbox clip-check">
                  <input type="checkbox" id={`filter_category_${idx}`} value={track.value} checked={track.selected} onChange={trackOnChange}/>
                  <label htmlFor={`filter_category_${idx}`}>
                    <span>{track.text}</span>
                  </label>
                </div>
              );
            })
          }
        </div>
      </div>
      <div className="col-md-4">
        <h4 className="text-bold">Registration Status</h4>
          <div style={{maxHeight: '200px', overflowY:'scroll'}}>
            {
              filters.status.map((status, idx) => {
              return (
                <div key={`filter_status_${idx}`} className="checkbox clip-check">
                  <input type="checkbox" id={`filter_status_${idx}`} value={status.value} checked={status.selected} onChange={trackOnChange}/>
                  <label htmlFor={`filter_status_${idx}`}>
                    <span>{status.text}</span>
                  </label>
                </div>
              );
            })
          }
        </div>
      </div>
      <div className="col-md-12 margin-top-10">
        <button type="button" className="btn btn-default btn-wide btn-sm btn-squared" onClick={onCancelClick}>Close</button>
      </div>
    </div>
  );
};


Filters.propTypes = propTypes;

export default Filters;
