import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { event } from '../../gtag'

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  eventDocuments: PropTypes.array.isRequired
};

const EventDocumentsModal = ({ showModal, onClose, eventDocuments }) => {
  return (
    <Modal show={showModal} onHide={onClose} bsSize="large">
      <Modal.Header closeButton className="bg-white">
        <Modal.Title>Download Info</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-white">
        <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
          <div style={{ fontSize: '15px', marginTop: '-20px' }} className="row buyers-guide">
            <div className="guide-data-main col-md-12">
              <div className="guide-data-box" style={{ padding: '20px 0px 0px' }}>
                {eventDocuments.map((document, index) => {
                  let formattedName = document.split(/uploads_+\d{5}_/)
                  if (formattedName.length > 1) formattedName = formattedName[1]
                  return <div key={index} style={{ paddingBottom: 10, display: 'flex', alignItems: 'center' }}>
                    <a
                      onClick={() => event({ action: 'download_link', category: 'engagement', label: 'Download Event Document' })}
                      target="_blank"
                      href={`https://s3.amazonaws.com/exposync-uploads/${document}`}
                      className="btn btn-air-red btn-xs btn-squared margin-right-10">
                      Download
                    </a>
                    <span>{formattedName}</span>
                  </div>
                })}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-white">
        <button className="btn btn-squared btn-sm btn-wide btn-air-red" onClick={onClose}>Close</button>
      </Modal.Footer>
    </Modal>
  );
};

EventDocumentsModal.propTypes = propTypes;


export default EventDocumentsModal;
