import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  individual: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  selection: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSortClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired
};

const defaultProps = { };

const Sorting = ({individual, options, selection, onChange, onSortClick, onCancelClick}) => {
  return (
    <div className="guide-data-box" style={{marginTop: '-10px'}}>
      <div className="col-md-4">
        <h4 className="text-bold">Sorting Criteria</h4>
        <div className="form-group">
					<label htmlFor="form-field-select-1">
						Sort by
					</label>
					<select id="sortBy_0" name="sortBy_0" value={selection.sortBy_0} onChange={onChange} className="form-control">
						<option value="">&nbsp;</option>
            <option value="exhibitor_name">Alphabetical</option>
            <option value="booth_location_2">Booth Location</option>
            <option value="interest">Interest Level</option>
					</select>
				</div>
        <div className="form-group">
					<label htmlFor="form-field-select-1">
						Then by:
					</label>
          <select id="sortBy_1" name="sortBy_1" value={selection.sortBy_1} onChange={onChange} className="form-control">
						<option value="">&nbsp;</option>
              <option value="booth_location_2">Booth Location</option>
              <option value="exhibitor_name">Alphabetical</option>
              <option value="interest">Interest Level</option>
					</select>
				</div>
        <div className="form-group">
					<label htmlFor="form-field-select-1">
						Then by:
					</label>
          <select id="sortBy_2" name="sortBy_2" value={selection.sortBy_2} onChange={onChange} className="form-control">
						<option value="">&nbsp;</option>
              <option value="booth_location_2">Booth Location</option>
              <option value="exhibitor_name">Alphabetical</option>
              <option value="interest">Interest Level</option>
					</select>
				</div>
      </div>
      <div className="col-md-12">
        <button type="button" className="btn btn-squared btn-air-red btn-wide btn-sm" onClick={onSortClick}>Sort</button>
        <button type="button" className="btn btn-default btn-wide btn-sm btn-squared margin-left-10" onClick={onCancelClick}>Close</button>
      </div>
    </div>
  );
};


Sorting.propTypes = propTypes;
Sorting.defaultProps = defaultProps;

export default Sorting;
