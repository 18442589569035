import * as types from './actionTypes';
import eventsApi from '../api/eventsApi';
import {beginAjaxCall} from './ajaxStatusActions';


export function loadEventsSuccess(events) {
   return { type: types.LOAD_EVENTS_SUCCESS, events};
 }

export function loadEvents(expo_id){
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return eventsApi.getAllEvents(expo_id).then(response => {
      dispatch(loadEventsSuccess(response.data));
    }).catch(error => {
      throw(error);
    });
  };
}
