import React from 'react';
import CategoriesTree from '../common/CategoriesTree';
import PropTypes from 'prop-types';

const Categories = ({individual, categories}) => {
  return (
      <div className="guide-data-box margin-top-0">
        <div className="row">
          <div className="col-md-6 col-sm-6 col-xs-12 hidden-print">
            <table style={{width: '100%'}}>
              <tbody>
                <tr>
                  <td className="hidden-print">
                    <span className="title">Categories </span><span className="subtitle">you have selected</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <CategoriesTree categories={categories} checkedKeys={[]} checkable={false} defaultExpandAll={false} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 hidden-print">
            <table style={{width: '100%'}}>
              <tbody>
                <tr>
                  <td><span className="title">Keywords </span><span className="subtitle">you have provided</span>
                  </td>
                </tr>
                <tr>
                  <td className="text-primary">{individual.keywords && individual.keywords.join(', ')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-xs-12 visible-print margin-bottom-10 padding-5" style={{border:'rgb(155, 178, 191) 1px solid'}}>
            {individual.categories && individual.categories.length > 0 && <div className="row">
              <div className="col-xs-12">
                <span style={{fontWeight: 'bold'}}>Categories: </span>
                  <span>{individual.categories.join(', ')}</span>
              </div>
            </div>}
              {individual.keywords && individual.keywords.length > 0 && <div className="row"><div className="col-xs-12 visible-print">
                <span style={{fontWeight: 'bold'}}>Keywords: </span>
                  <span>{individual.keywords.join(', ')}</span>
              </div>
            </div>}
          </div>
        </div>
      </div>
  );
};

Categories.propTypes = {
  individual: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired
};


export default Categories;
