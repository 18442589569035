import React from "react";
import PropTypes from "prop-types";
import renderHTML from "react-render-html";
import sanitizeHtml from "sanitize-html";

const propTypes = {
  expo: PropTypes.object.isRequired,
};
const MarketplaceClosedPage = ({ expo }) => {
  let message = "";
  if (expo?.guide_settings?.marketplace_closed_message) {
    message = sanitizeHtml(expo.guide_settings.marketplace_closed_message, {
      allowedAttributes: false,
    }).replace(/(?:\r\n|\r|\n)/g, "<br />");
  }

  return (
    <div className="guide-data-main col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
      <div className="guide-data-box padding-top-20 padding-right-5">
        <div className="row">
          <div className="col-sm-12 col-xs-12 col-md-offset-2 col-md-8">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <a target="_blank" href={expo.website_url}>
                      {expo && expo.expo_logo_url && (
                        <img
                          style={{ maxHeight: "100px", maxWidth: "100%" }}
                          src={`https://s3.amazonaws.com/exposync-uploads/${expo.expo_logo_url}`}
                        />
                      )}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ textAlign: "center" }}
                    className="padding-top-10"
                  >
                    <span>{renderHTML(message)}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

MarketplaceClosedPage.propTypes = propTypes;

export default MarketplaceClosedPage;
