import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/TextInput';
import guideApi from '../../api/guideApi';
import styled from 'styled-components';

const SubmitButton = styled.button`
width: 100%;
font-size: 1.125rem;

background-image: none;
display: inline-block;
font-size: .875rem;
line-height: normal;
margin-right: 8px;
padding: 0.55rem 1rem;
padding-top: 0.55rem;
padding-right: 1rem;
padding-bottom: 0.55rem;
padding-left: 1rem;
text-align: center;
text-decoration: none;
transition: all 0.2s ease 0s;

background-color: #e57f22;
border: 2px solid #e57f22;
color: white;
border-radius: .5rem;

:hover {
  background-color: hsl(26, 84%, 50%);
  border: 2px solid hsl(26, 84%, 50%);
}
:focus, :active {
  background-color: hsl(26, 84%, 40%);
  border: 2px solid hsl(26, 84%, 40%);
}
`

const Wrapper = styled.div`
background-color: #f5f5f5;
margin: auto;
max-width: 100%;
padding: 1.5rem;
padding-bottom: 3rem;
display: flex;
flex-direction: column;
position: absolute;
bottom: 0;
top: 0;
right: 0;
left: 0;
${props => props.submitted ? 'align-items: center; justify-content: center;' : ''}
h1 {
  color: hsl(210, 100%, 21%);
  margin-bottom: 2rem;
  font-size: 2em;
  font-weight: bold;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
}
h2, span, ol {
  color: #333;
  align-self: center;
  text-align: left;
}
h2 {
  padding-bottom: 10px;
  font-size: calc(18px + 2 * ((100vw - 320px) / 880));
  font-weight: 400;
  @media (min-width: 1200px) {
    font-size: 20px;
  }
}
li, span {
  text-align: left;
}
.form-group {
  label {
    color: #333;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    font-weight: 400;
    margin-bottom: 0;
    @media (min-width: 320px) {
      font-size: calc(14px + 2 * ((100vw - 320px) / 880));
    }
    @media (min-width: 1200px) {
      font-size: 16px;
    }
  }
  input {
    :placeholder-shown {
      font-style: italic;
    }
    :-internal-autofill-selected {
      background-color: white !important;
    }
  }
}
.form-control {
  margin: .5rem .5rem .5rem 0;
  box-sizing: border-box;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0,0,0,0.1);
  box-shadow: none;
  color: rgba(0,0,0,0.7);
  height: 38px;
  padding: 0 .5rem;
  border-radius: .125rem;
  :focus {
    background-color: #f4f4f4 !important;
    border-color: hsl(26, 84%, 60%) !important;
  }
}
`
const propTypes = {
};
const MarketplaceGuidePage = ({ match }) => {
  const [submitted, setSubmitted] = useState(false)
  const [nameFirst, setNameFirst] = useState('');
  const [nameLast, setNameLast] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({
    nameFirst: '',
    nameLast: '',
    businessName: '',
    email: ''
  });
  const [disableButton, setDisableButton] = useState(false);
  const expo_id = match.params.id;

  const validateEmailAddress = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const toggleSubmitted = () => setSubmitted(!submitted)

  const onChange = (event, variable) => {
    const value = event.target.value;
    switch (variable) {
      case 'nameFirst': setNameFirst(value); break;
      case 'nameLast': setNameLast(value); break;
      case 'businessName': setBusinessName(value); break;
      case 'email': setEmail(value); break;
      default: break;
    }
  };

  const submit = async (event) => {
    event.preventDefault();
    let foundErrors = false;

    let _errors = {};
    if (!nameFirst) {
      _errors.nameFirst = 'This field is required.';
      foundErrors = true;
    }
    if (!nameLast) {
      _errors.nameLast = 'This field is required.';
      foundErrors = true;
    }
    if (!businessName) {
      _errors.businessName = 'This field is required.';
      foundErrors = true;
    }
    if (!email) {
      _errors.email = 'This field is required.';
      foundErrors = true;
    }
    else if (!validateEmailAddress(email)) {
      _errors.email = 'email address is not valid';
      foundErrors = true;
    }
    if (foundErrors) {
      setErrors(Object.assign({}, _errors));
      return;
    }
    const data = {
      name_first: nameFirst,
      name_last: nameLast,
      business_name: businessName,
      email_first: email,
      expo_id
    };

    setDisableButton(true)
    const response = await guideApi.createMarketplaceGuide(data);
    // if(response.status === 200)
    setDisableButton(false)
    toggleSubmitted()
  };

  return (
    <Wrapper submitted={submitted}>
      {submitted ? <>
        <div>
          <h2>An email has been sent to <strong>{email}</strong></h2>
          <span>If you do not receive the email in a minute:</span>
          <ol>
            <li>There may have been a typo in your email, please <a onClick={toggleSubmitted}>resubmit form</a></li>
            <li>Check that your email provider will accept emails from <strong>info@email.ippexpo.org</strong></li>
            <li>Click <a onClick={toggleSubmitted}>here to try again</a></li>
            <li>Contact IPPE Management at <a href={"mailto:info@ippexpo.org"} target="_blank">info@ippexpo.org</a></li>
          </ol>
        </div>
      </>
        : <>
          <h1>Request Your Personal Marketplace Guide</h1>
          <form>
            <TextInput name="name_first" label="First Name" value={nameFirst} onChange={(e) => onChange(e, 'nameFirst')} error={errors.nameFirst} />
            <TextInput name="name_last" label="Last Name" value={nameLast} onChange={(e) => onChange(e, 'nameLast')} error={errors.nameLast} />
            <TextInput name="business_name" label="Business Name" value={businessName} onChange={(e) => onChange(e, 'businessName')} error={errors.businessName} />
            <TextInput name="email_first" label="Email" value={email} placeholder="name@example.com" onChange={(e) => onChange(e, 'email')} error={errors.email} />
            <SubmitButton disabled={disableButton} type="button" onClick={submit}>Click to Receive Access to Your Personal Guide</SubmitButton>
          </form>
        </>}
    </Wrapper>
  );
};

MarketplaceGuidePage.propTypes = propTypes;

export default MarketplaceGuidePage;
