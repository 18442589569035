import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as guideActions from '../../actions/guideActions';
import * as eventActions from '../../actions/eventActions';
import ExhibitorsList from './ExhibitorsList';
import Header from '../common/Header';
import Categories from './Categories';
import Footer from '../common/Footer';
import { toTreeFormat } from '../common/utils/categories';
import Head from '../common/Head';
import PropTypes from 'prop-types';
import { AuthContext } from '../../context/auth'

const firstBy = require('thenby');

import ReactGA from 'react-ga';
ReactGA.initialize('UA-78353111-1');

class GuidePrintPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      expo: Object.assign({}, this.props.expo),
      individual: Object.assign({}, this.props.individual),
      exhibitors: [...this.props.exhibitors],
      unfiltered_exhibitors: [...this.props.exhibitors],
      errors: {},
      sorterSelection: {
        sortBy_0: 'exhibitor_name',
        sortBy_1: '',
        sortBy_2: ''
      },
      filters: {
        categories: [],
        interestLevels: [
          {
            text: 'Not Selected',
            value: 'w_none',
            selected: true
          }, {
            text: 'Interested',
            value: 'interested',
            selected: true
          }, {
            text: 'Maybe',
            value: 'maybe',
            selected: true
          }
        ],
        keywords: [],
        locations: []
      },
      data_filtered: false,
      original_filters: {},
      removedListVisible: false,
      showSendEmailModal: false,
      showBoothLocation: true
    };

    this.onFilterApply = this.onFilterApply.bind(this);
    this.onSortApply = this.onSortApply.bind(this);
    this.setInterestLevel = this.setInterestLevel.bind(this);
    this.onWebsiteClick = this.onWebsiteClick.bind(this);
    this.onSendEmailClick = this.onSendEmailClick.bind(this);


    this.sendEmailModalOnOpen = this.sendEmailModalOnOpen.bind(this);
    this.sendEmailModalOnClose = this.sendEmailModalOnClose.bind(this);
  }

  componentWillMount() {

    if (!this.props.individual.id) {
      Promise.resolve(this.props.actions.loadIndividual(this.props.individualId, this.props.synced))
      .then(async () => {
        const expo_id = this.props.individual.expo_id;
        await this.context.checkIfMarketplaceClosed(expo_id)
        this.props.actions.loadExpo(expo_id);
        Promise.resolve(this.props.actions.loadExhibitors(this.props.individual)).then(() => {
          this.setInitialFilters(this.props.individual, this.props.exhibitors);
          this.setFiltersAndSorting();
          //Google Analytics
          ReactGA.event({ category: 'Personal Guide', action: 'print_guide' });
          // skip print if marketplace is closed and user is not authenticated
          if(!this.context.isMarketplaceClosed || this.context.isAuthenticated)
          setTimeout(function () { 
            window.print(); 
          }, 3000);

        });

      });
    } else {
      this.setInitialFilters(this.props.individual, this.props.exhibitors);
    }
  }

  componentWillReceiveProps(nextProps) {

    const { exhibitors, data_filtered } = this.state;
    if (!data_filtered) {
      if (nextProps.exhibitors.length != exhibitors.length) {
        this.setState({
          exhibitors: [...nextProps.exhibitors],
          unfiltered_exhibitors: [...nextProps.exhibitors]
        });
        this.sortExhibitors();
      }
    }

  }

  onSortApply(sorterSelection) {
    let exhibitors = [...this.state.exhibitors];
    exhibitors = exhibitors.sort(firstBy(sorterSelection.sortBy_0, { ignoreCase: true }).thenBy(sorterSelection.sortBy_1, { ignoreCase: true }).thenBy(sorterSelection.sortBy_2, { ignoreCase: true }));

    this.setState({
      sorterSelection: Object.assign({}, sorterSelection),
      exhibitors: [...exhibitors]
    });
  }

  onFilterApply(filters) {
    const filtered = this.filtersOn(filters);
    this.setState({ filters: Object.assign({}, filters), data_filtered: filtered });
    this.filterData();
  }

  filtersOn(filters) {
    let filtered = false;
    filters.categories.forEach(item => {
      if (!item.selected)
        filtered = true;
    }
    );

    if (filtered)
      return true;

    filters.keywords.forEach(item => {
      if (!item.selected)
        filtered = true;
    }
    );

    filters.locations.forEach(item => {
      if (!item.selected)
        filtered = true;
    }
    );

    filters.interestLevels.forEach(item => {
      if (!item.selected)
        filtered = true;
    }
    );

    return filtered;
  }

  sortExhibitors() {
    const { sorterSelection } = this.state;
    let exhibitors = [...this.state.exhibitors];

    exhibitors = exhibitors.sort(firstBy(sorterSelection.sortBy_0, { ignoreCase: true }).thenBy(sorterSelection.sortBy_1, { ignoreCase: true }).thenBy(sorterSelection.sortBy_2, { ignoreCase: true }));
    this.setState({
      exhibitors: [...exhibitors]
    });
  }

  filterData() {
    const filters = Object.assign({}, this.state.filters);
    const individual = Object.assign({}, this.props.individual);
    const exhibitors = [...this.state.unfiltered_exhibitors];
    const { showBoothLocation } = this.state

    const categories = filters.categories.filter(c => {
      return c.selected == true;
    });
    const keywords = filters.keywords.filter(c => {
      return c.selected == true;
    });
    const interests = filters.interestLevels.filter(c => {
      return c.selected == true;
    });
    const locations = filters.locations.filter(c => {
      if (c.value === "Not Specified") return true
      return c.selected == true;
    });
    if (exhibitors.length > 0) {
      let filteredExhibitors = exhibitors.filter(exh => {

        const categoryMatch = categories.filter(c => {
          if (exh.matched_categories)
            return exh.matched_categories.indexOf(c.value) > -1;
        });

        const keywordMatch = keywords.filter(k => {
          if (exh.matched_keywords)
            return exh.matched_keywords.indexOf(k.value) > -1;
        });

        return (categoryMatch !== null && categoryMatch.length > 0) || (keywordMatch !== null && keywordMatch.length > 0) || individual.exhibitors_interested.indexOf(exh.id) > -1;
      });

      if (interests && interests.length > 0) {
        filteredExhibitors = filteredExhibitors.filter(exh => {
          const interestMatch = interests.filter(l => {
            return exh.interest === l.value;
          });
          return interestMatch !== null && interestMatch.length > 0;
        });
      }

      if (showBoothLocation && locations && locations.filter(l => l.value !== 'Not Specified').length > 0) {
        filteredExhibitors = filteredExhibitors.filter(exh => {
          const locationMatch = locations.filter(l => {
            if (l.value === 'Not Specified' && l.selected && !exh.booth_location) return true
            return exh.booth_location === l.value;
          });
          return locationMatch !== null && locationMatch.length > 0;
        });
      }

      this.setState({
        exhibitors: [...filteredExhibitors]
      });
    }

  }

  getBoothLocations(exhibitors) {
    let locations = [];
    exhibitors.forEach(exhibitor => {
      if (exhibitor && exhibitor.booth_location) {
        let idx = locations.findIndex(function (element) {
          return element.value == exhibitor.booth_location;
        });

        if (locations.findIndex(function (element) {
          return element.value === exhibitor.booth_location;
        }) === -1) {
          locations.push({ text: exhibitor.booth_location, value: exhibitor.booth_location, selected: true });
        }
      }
    });
    return locations;
  }

  setInitialFilters(individual, exhibitors) {

    if (individual.guide_preferences && individual.guide_preferences.sorting) {
      this.setState({ sorterSelection: Object.assign({}, individual.guide_preferences.sorting) });
    }
    if (individual.guide_preferences && individual.guide_preferences.filters) {
      this.setState({ filters: Object.assign({}, individual.guide_preferences.filters) });
    } else {
      let filters = Object.assign({}, this.state.filters);
      filters.locations = [...this.getBoothLocations(exhibitors)];
      filters.categories = individual.categories.map(category => {
        return { text: category, value: category, selected: true };
      });
      filters.keywords = individual.keywords.map(keyword => {
        return { text: keyword, value: keyword, selected: true };
      });
      this.setState({ filters: Object.assign({}, filters), original_filters: Object.assign({}, filters) });
    }

    this.sortExhibitors();

  }

  setFiltersAndSorting() {
    if (!(localStorage.getItem("FILTERS") === null)) {
      let filters = JSON.parse(localStorage.getItem("FILTERS"));
      const showBoothLocation = this.props.expo.guide_settings.show_booth_location
      this.setState({ filters: Object.assign({}, filters), showBoothLocation });
      this.onFilterApply(filters);
    }

    if (!(localStorage.getItem("SORTING") === null)) {
      let sorting = JSON.parse(localStorage.getItem("SORTING"));
      this.setState({ sorterSelection: Object.assign({}, sorting) });

      this.onSortApply(sorting);
    }
  }

  setInitialInterest(individual, id) {
    if (individual.exhibitors_interested.indexOf(id) > -1)
      return 'interested';
    if (individual.exhibitors_maybe.indexOf(id) > -1)
      return 'maybe';
    if (individual.exhibitors_notinterested.indexOf(id) > -1)
      return 'notinterested';
    return 'w_none';
  }

  setInterestLevel(exhibitor, level) {

  }

  onWebsiteClick(exhibitor) {
    if (exhibitor && exhibitor.exhibitor_website) {
      let individual = Object.assign({}, this.props.individual);
      let website = exhibitor.exhibitor_website;
      if (website.substring(0, 4) !== 'http')
        website = 'http://' + website;
      if (!(individual.exhibitors_clicked.indexOf(exhibitor.id) > -1)) {
        let data = {};
        data.exhibitors_clicked = [...individual.exhibitors_clicked];
        data.exhibitors_clicked.push(exhibitor.id);
        this.props.actions.updateIndividual(individual.id, data);
      }
      window.open(website, '_blank');

    }
  }

  onSendEmailClick(exhibitor) { }

  sendEmailModalOnClose() { }

  sendEmailModalOnOpen() { }

  render() {
    const {
      sorterSelection,
      filters,
      original_filters,
      removedListVisible,
      showSendEmailModal
    } = this.state;

    const { expo, individual, exhibitors } = this.props;

    return (
      <div>
        <Head expo={expo} individual={individual} />
        <div className="row buyers-guide">
          <div className="guide-data-main col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
            <Header individual={individual} expo={expo} />
            <Categories individual={individual} categories={toTreeFormat(individual.categories)} checkedKeys={individual.categories} />
            <ExhibitorsList individual={individual} expo={expo} exhibitors={exhibitors.filter(exhibitor => {
              return exhibitor.interest !== 'notinterested';
            })} setInterestLevel={this.setInterestLevel} onWebsiteClick={this.onWebsiteClick} onSendEmailClick={this.onSendEmailClick} printVersion />
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

GuidePrintPage.propTypes = {
  exhibitors: PropTypes.array.isRequired,
  expo: PropTypes.object.isRequired,
  individual: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  individualId: PropTypes.string.isRequired,
  synced: PropTypes.bool.isRequired
};

GuidePrintPage.contextType = AuthContext;

function mapStateToProps(state, ownProps) {
  return { individualId: ownProps.match.params.id, exhibitors: state.exhibitors, expo: state.expo, individual: state.individual, synced: state.synced };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, eventActions, guideActions), dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GuidePrintPage);
