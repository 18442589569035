import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Mustache from 'mustache';

const propTypes = {
  individual: PropTypes.object.isRequired,
  expo: PropTypes.object.isRequired,
  eventsPage: PropTypes.bool


};
const Head = ({individual, expo, eventsPage}) => {

  const home_screen_icon_url = 'https://s3.amazonaws.com/exposync-uploads/' + expo.home_screen_icon_url;
  let personal_guide_title = '...';
  if (expo && expo.guide_settings) {
      personal_guide_title =  Mustache.render(expo.guide_settings.guide_title, individual);
  }

  const title = eventsPage ? 'Events - ' + individual.name_first + ' ' + individual.name_last : personal_guide_title;
  return (
    <Helmet
      title={title}
      link={[
              {rel: "apple-touch-icon", href: home_screen_icon_url},
              {rel: "shortcut icon", href: home_screen_icon_url}
            ]}
       />
  );
};

Head.propTypes = propTypes;

export default Head;
