import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as guideActions from "../../actions/guideActions";
import * as eventActions from "../../actions/eventActions";
import ExhibitorsList from "./ExhibitorsList";
import Header from "../common/Header";
import Categories from "./Categories";
import FilterBar from "./FilterBar";
import Footer from "../common/Footer";
import { toTreeFormat } from "../common/utils/categories";
import SendEmailModal from "./SendEmailModal";
import Head from "../common/Head";
import PropTypes from "prop-types";
import Spinner from "../common/Spinner";
import { AuthContext } from '../../context/auth'

const firstBy = require("thenby");

class GuidePage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      expo: Object.assign({}, this.props.expo),
      individual: Object.assign({}, this.props.individual),
      exhibitors: [...this.props.exhibitors],
      unfiltered_exhibitors: [...this.props.exhibitors],
      errors: {},
      guide_prefs: {},
      sorterSelection: {
        sortBy_0: "exhibitor_name",
        sortBy_1: "",
        sortBy_2: "",
      },
      filters: {
        categories: [],
        interestLevels: [
          {
            text: "Not Selected",
            value: "w_none",
            selected: true,
          },
          {
            text: "Interested",
            value: "interested",
            selected: true,
          },
          {
            text: "Maybe",
            value: "maybe",
            selected: true,
          },
        ],
        keywords: [],
        locations: [],
      },
      data_filtered: false,
      original_filters: {},
      removedListVisible: false,
      showSendEmailModal: false,
      loading: true,
    };

    this.onFilterApply = this.onFilterApply.bind(this);
    this.onSortApply = this.onSortApply.bind(this);
    this.setInterestLevel = this.setInterestLevel.bind(this);
    this.onWebsiteClick = this.onWebsiteClick.bind(this);
    this.onSendEmailClick = this.onSendEmailClick.bind(this);
    this.onShowHideRemovedList = this.onShowHideRemovedList.bind(this);

    this.sendEmailModalOnOpen = this.sendEmailModalOnOpen.bind(this);
    this.sendEmailModalOnClose = this.sendEmailModalOnClose.bind(this);

    this.onShowEventsClick = this.onShowEventsClick.bind(this);
  }

  componentWillMount() {
    //if (!this.props.individual.id) {
    Promise.resolve(
      this.props.actions.loadIndividual(this.props.individualId, false)
    ).then(async (guide_prefs) => {
      const expo_id = this.props.individual.expo_id;
      await this.context.checkIfMarketplaceClosed(expo_id)
      this.props.actions.loadExpo(expo_id);
      this.setState({ guide_prefs: Object.assign({}, guide_prefs), individual: this.props.individual });
      Promise.resolve(this.props.actions.loadExhibitors(this.props.individual))
        .then(() => {
          this.setInitialFilters(this.props.individual, this.props.exhibitors);
          this.setState({ loading: false });
        })
    });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillReceiveProps(nextProps) {
    const { exhibitors, data_filtered } = this.state;
    if (!data_filtered) {
      if (nextProps.exhibitors.length != exhibitors.length) {
        this.setState({
          exhibitors: [...nextProps.exhibitors],
          unfiltered_exhibitors: [...nextProps.exhibitors],
          loading: false,
        });
      }
    }
  }

  onSortApply(sorterSelection) {
    const { filters } = this.state;
    let exhibitors = [...this.state.exhibitors];
    exhibitors = exhibitors.sort(
      firstBy(sorterSelection.sortBy_0, { ignoreCase: true })
        .thenBy(sorterSelection.sortBy_1, { ignoreCase: true })
        .thenBy(sorterSelection.sortBy_2, { ignoreCase: true })
    );
    this.setState({
      sorterSelection: Object.assign({}, sorterSelection),
      exhibitors: [...exhibitors],
    });
    localStorage.setItem("SORTING", JSON.stringify(sorterSelection));

    let data = {
      guide_preferences: {},
    };
    data.guide_preferences.sorting = Object.assign({}, sorterSelection);
    data.guide_preferences.filters = Object.assign({}, filters);
    this.props.actions.updateIndividualPreferences(
      this.props.individualId,
      data
    );
  }

  onFilterApply(filters) {
    const { sorterSelection } = this.state;
    this.setState({ filters: Object.assign({}, filters) });
    localStorage.setItem("FILTERS", JSON.stringify(filters));

    //update guide preferences
    let data = {
      guide_preferences: {},
    };

    data.guide_preferences.filters = Object.assign({}, filters);
    data.guide_preferences.sorting = Object.assign({}, sorterSelection);
    this.props.actions.updateIndividualPreferences(
      this.props.individualId,
      data
    );
  }

  sortExhibitors(exhibitors) {
    const { sorterSelection } = this.state;
    if (sorterSelection.sortBy_0) {
      exhibitors = exhibitors.sort(
        firstBy(sorterSelection.sortBy_0, { ignoreCase: true })
          .thenBy(sorterSelection.sortBy_1, { ignoreCase: true })
          .thenBy(sorterSelection.sortBy_2, { ignoreCase: true })
      );
    } else {
      exhibitors = exhibitors.sort(firstBy("id", { ignoreCase: true }));
    }

    return [...exhibitors];
  }

  filterExhibitors(filters, individual, exhibitors, showBoothLocation) {
    if (!(exhibitors && exhibitors.length > 0)) return [];
    const categories = filters.categories.filter((c) => {
      return c.selected == true;
    });
    const keywords = filters.keywords.filter((c) => {
      return c.selected == true;
    });
    const interests = filters.interestLevels.filter((c) => {
      return c.selected == true;
    });
    const locations = filters.locations.filter((c) => {
      if (c.value === "Not Specified") return true;
      return c.selected == true;
    });

    let filteredExhibitors = exhibitors.filter((exh) => {
      const categoryMatch = categories.filter((c) => {
        if (exh.matched_categories)
          return exh.matched_categories.indexOf(c.value) > -1;
      });
      const keywordMatch = keywords.filter((k) => {
        if (exh.matched_keywords)
          return exh.matched_keywords.indexOf(k.value) > -1;
      });

      return (
        (categoryMatch !== null && categoryMatch.length > 0) ||
        (keywordMatch !== null && keywordMatch.length > 0) ||
        individual.exhibitors_interested.indexOf(exh.id) > -1
      );
    });

    if (interests && interests.length > 0) {
      filteredExhibitors = filteredExhibitors.filter((exh) => {
        const interestMatch = interests.filter((l) => {
          return exh.interest === l.value;
        });
        return interestMatch !== null && interestMatch.length > 0;
      });
    }

    if (
      showBoothLocation &&
      locations &&
      locations.filter((l) => l.value !== "Not Specified").length > 0
    ) {
      filteredExhibitors = filteredExhibitors.filter((exh) => {
        const locationMatch = locations.filter((l) => {
          if (l.value === "Not Specified" && l.selected && !exh.booth_location)
            return true;
          return exh.booth_location === l.value;
        });
        return locationMatch !== null && locationMatch.length > 0;
      });
    }

    return [...filteredExhibitors];
  }

  getBoothLocations(exhibitors) {
    let locations = [];
    exhibitors.forEach((exhibitor) => {
      if (exhibitor && exhibitor.booth_location) {
        let idx = locations.findIndex(function (element) {
          return element.value == exhibitor.booth_location;
        });

        if (
          locations.findIndex(function (element) {
            return element.value === exhibitor.booth_location;
          }) === -1
        ) {
          locations.push({
            text: exhibitor.booth_location,
            value: exhibitor.booth_location,
            selected: true,
          });
        }
      }
    });
    return locations;
  }

  setInitialFilters(individual, exhibitors) {
    let filters = Object.assign({}, this.state.filters);
    const guide_prefs = Object.assign({}, this.state.guide_prefs);
    ///filters
    if (guide_prefs && guide_prefs.filters) {
      filters.categories = [...guide_prefs.filters.categories];
      filters.keywords = [...guide_prefs.filters.keywords];
      filters.interestLevels = [...guide_prefs.filters.interestLevels];
      filters.locations = [...guide_prefs.filters.locations];
      if (!filters.locations.find((x) => x.value === "Not Specified"))
        filters.locations.unshift({
          selected: true,
          text: "Not Specified",
          value: "Not Specified",
        });
      this.setState({ filters: filters });
    } else {
      filters.categories = individual.categories.map((category) => {
        return { text: category, value: category, selected: true };
      });
      filters.keywords = individual.keywords.map((keyword) => {
        return { text: keyword, value: keyword, selected: true };
      });
      filters.locations = [...this.getBoothLocations(exhibitors)];
      if (!filters.locations.find((x) => x.value === "Not Specified"))
        filters.locations.unshift({
          selected: true,
          text: "Not Specified",
          value: "Not Specified",
        });
      filters.interestLevels = [...filters.interestLevels];
      this.setState({ filters: filters });
    }
    //sorting
    if (guide_prefs && guide_prefs.sorting) {
      this.setState({
        sorterSelection: Object.assign({}, guide_prefs.sorting),
      });
    }
  }

  setInitialInterest(individual, id) {
    if (individual.exhibitors_interested.indexOf(id) > -1) return "interested";
    if (individual.exhibitors_maybe.indexOf(id) > -1) return "maybe";
    if (individual.exhibitors_notinterested.indexOf(id) > -1)
      return "notinterested";
    return "w_none";
  }

  setInterestLevel(exhibitor, level) {
    let individual = Object.assign({}, this.state.individual);
    let data = {};

    let exhibitors_interested = individual.exhibitors_interested.filter(
      (x) => x !== exhibitor.id
    );
    let exhibitors_maybe = individual.exhibitors_maybe.filter(
      (x) => x !== exhibitor.id
    );
    let exhibitors_notinterested = individual.exhibitors_notinterested.filter(
      (x) => x !== exhibitor.id
    );

    if (level === "interested") {
      if (!(individual.exhibitors_interested.indexOf(exhibitor.id) > -1)) {
        exhibitors_interested.push(exhibitor.id);
      }
      else return
    } else if (level === "maybe") {
      if (!(individual.exhibitors_maybe.indexOf(exhibitor.id) > -1)) {
        exhibitors_maybe.push(exhibitor.id);
      }
      else return
    } else if (level === "notinterested") {
      if (!(individual.exhibitors_notinterested.indexOf(exhibitor.id) > -1)) {
        exhibitors_notinterested.push(exhibitor.id);
      }
      else return
    }

    data.exhibitors_interested = exhibitors_interested;
    data.exhibitors_maybe = exhibitors_maybe;
    data.exhibitors_notinterested = exhibitors_notinterested;

    this.setState({ individual: { ...individual, ...data } })

    let ex = Object.assign({}, exhibitor);
    ex.interest = level;

    this.props.actions.updateIndividual(this.props.individualId, data, ex);

    let exhibitors = [...this.state.exhibitors];
    exhibitors = [
      ...exhibitors.filter((exh) => exh.id !== ex.id),
      Object.assign({}, ex),
    ];

    this.setState({
      exhibitors: [...exhibitors],
    });
  }

  onWebsiteClick(exhibitor, website) {
    if (exhibitor && website) {
      let individual = Object.assign({}, this.props.individual);
      if (website.substring(0, 4) !== "http") website = "http://" + website;
      if (!(individual.exhibitors_clicked.indexOf(exhibitor.id) > -1)) {
        let data = {};
        data.exhibitors_clicked = [...individual.exhibitors_clicked];
        data.exhibitors_clicked.push(exhibitor.id);
        this.props.actions.updateIndividual(individual.id, data);
      }
      window.open(website, "_blank");
    }
  }

  onSendEmailClick(exhibitor) {
    this.setState({ showSendEmailModal: true });
  }

  sendEmailModalOnClose() {
    this.setState({ showSendEmailModal: false });
  }

  sendEmailModalOnOpen() {
    this.setState({ showSendEmailModal: true });
  }

  onShowHideRemovedList() {
    const { removedListVisible } = this.state;
    this.setState({
      removedListVisible: !removedListVisible,
    });
  }

  onShowEventsClick() {
    const { individual } = this.props;
    this.props.history.push("/events/" + individual.id);
  }

  handleScroll() {
    let y = window.scrollY;
    let winHeight = window.innerHeight;
    let body = document.body;
    let html = document.documentElement;
    let mainFilterBarTop = 260;
    if (document.getElementById("mainFilterBar")) {
      mainFilterBarTop = document.getElementById("mainFilterBar").offsetTop;
    }
    let docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    let sorterTop = document.getElementById("sorterTop");
    if (sorterTop) {
      if (y >= mainFilterBarTop)
        sorterTop.className = "topMenu show border-light";
      else sorterTop.className = "topMenu hide";
    }
  }

  render() {
    const {
      sorterSelection,
      filters,
      original_filters,
      removedListVisible,
      showSendEmailModal,
      loading,
    } = this.state;
    const { expo, individual } = this.props;
    //filter
    let exhibitors = [...this.state.exhibitors];
    const showBoothLocation =
      !expo.guide_settings || expo.guide_settings.show_booth_location;
    let filteredExhibitors = this.filterExhibitors(
      filters,
      individual,
      exhibitors,
      showBoothLocation
    );
    //sort
    filteredExhibitors = this.sortExhibitors(filteredExhibitors);
    exhibitors = this.sortExhibitors(exhibitors);

    let individual_copy = Object.assign({}, individual, {
      guide_preferences: {},
    });

    return (
      <div>
        <Head expo={expo} individual={individual_copy} />
        <div className="row buyers-guide">
          <div className="guide-data-main col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
            <Header individual={individual_copy} expo={expo} exhibitorsPage />
            {loading ? (
              <div className="guide-data-box">
                <Spinner />{" "}
              </div>
            ) : (
              <>
                <Categories
                  individual={individual_copy}
                  categories={toTreeFormat(individual_copy.categories)}
                  checkedKeys={individual_copy.categories}
                />
                <div id="mainFilterBar">
                  <FilterBar
                    expo={expo}
                    individual={individual_copy}
                    filters={filters}
                    original_filters={original_filters}
                    onFilterApply={this.onFilterApply}
                    sorterSelection={sorterSelection}
                    onSortApply={this.onSortApply}
                    removedListVisible={removedListVisible}
                    onShowHideRemovedList={this.onShowHideRemovedList}
                    showBoothLocation={showBoothLocation}
                  />
                </div>
                {!removedListVisible && (
                  <ExhibitorsList
                    individual={individual_copy}
                    expo={expo}
                    exhibitors={filteredExhibitors.filter((exhibitor) => {
                      return exhibitor.interest !== "notinterested";
                    })}
                    setInterestLevel={this.setInterestLevel}
                    onWebsiteClick={this.onWebsiteClick}
                    onSendEmailClick={this.onSendEmailClick}
                  />
                )}
                {removedListVisible && (
                  <ExhibitorsList
                    individual={individual_copy}
                    expo={expo}
                    exhibitors={exhibitors.filter((exhibitor) => {
                      return exhibitor.interest === "notinterested";
                    })}
                    excludedList
                    showHideExcludedList={this.showHideExcludedList}
                    excludedListVisible={removedListVisible}
                    setInterestLevel={this.setInterestLevel}
                    onWebsiteClick={this.onWebsiteClick}
                    onSendEmailClick={this.onSendEmailClick}
                  />
                )}
              </>
            )}
            <Footer />
          </div>
        </div>
        <div id="sorterTop" className="topMenu hide">
          <div className="row buyers-guide">
            <div className="guide-data-main col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
              <FilterBar
                expo={expo}
                individual={individual_copy}
                filters={filters}
                original_filters={original_filters}
                onFilterApply={this.onFilterApply}
                sorterSelection={sorterSelection}
                onSortApply={this.onSortApply}
                removedListVisible={removedListVisible}
                onShowHideRemovedList={this.onShowHideRemovedList}
                showBoothLocation={showBoothLocation}
              />
            </div>
          </div>
        </div>
        <SendEmailModal
          showModal={showSendEmailModal}
          onClose={this.sendEmailModalOnClose}
        />
      </div>
    );
  }
}

GuidePage.propTypes = {
  exhibitors: PropTypes.array.isRequired,
  expo: PropTypes.object.isRequired,
  individual: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  individualId: PropTypes.string.isRequired,
  synced: PropTypes.bool.isRequired,
};

GuidePage.contextType = AuthContext;

function mapStateToProps(state, ownProps) {
  return {
    individualId: ownProps.match.params.id,
    exhibitors: state.exhibitors,
    expo: state.expo,
    individual: state.individual,
    guide_prefs: state.guidePrefs,
    synced: state.synced,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign({}, eventActions, guideActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GuidePage);
