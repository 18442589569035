const constants = { 
  GA_TRACKING_ID: 'G-J9GDLJZBJB'
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  if (window)
    window.gtag("config", constants.GA_TRACKING_ID, {
      page_path: url,
    });
};
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  if (window)
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
    });
};
