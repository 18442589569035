
import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    fields: PropTypes.object.isRequired
};

const FeaturedBar = ({ fields }) => {
    const New_Exhibitor = fields.New_Exhibitor == 'TRUE' ? true: false;
    const Featured = fields.Featured == 'TRUE' ? true: false;
    const Gallery = fields.Gallery == 'TRUE' ? true: false;
    const Show_Special = fields.Show_Special == 'TRUE' ? true: false;
    const Signature = fields.Signature == 'TRUE' ? true: false;

    return (
        <div className="pull-right" style={{ display: 'flex' }}>
            {/* {New_Exhibitor && <a title="New Exhibitor" className="w-25 avatar circle red margin-left-5">N</a>} */}
            {New_Exhibitor && <a className="blue-dark avatar rectangle margin-left-5">Featured Exhibitor</a>}
            {Featured && <a title="Featured" className="w-25 avatar circle blue margin-left-5">F</a>}
            {Gallery && <a title="Gallery" className="w-25 avatar circle purple margin-left-5">G</a>}
            {Show_Special && <a title="Show Specials"  className="w-25 avatar circle green margin-left-5">$</a>}
            {Signature && <a title="Signature"  className="w-25 avatar circle blue-grey margin-left-5">S</a>}
        </div>
    );
};

FeaturedBar.propTypes = propTypes;

export default FeaturedBar;