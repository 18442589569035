import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as eventActions from '../../actions/eventActions';
import * as guideActions from '../../actions/guideActions';
import Header from '../common/Header';
import Footer from '../common/Footer';
import EventList from './EventList';
import Head from '../common/Head';
const firstBy = require('thenby');
import EventDocumentsModal from './EventDocumentsModal'
import { AuthContext } from '../../context/auth'

class EventsPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      individual: Object.assign({}, this.props.individual),
      errors: {},
      sort_by: 'event_start',
      filters: {
        tracks: [],
        status: [{text: 'Registered', value: 'attending', selected: true},
                {text: 'Not Registered', value: 'not_attending', selected: true}]
      },
      show_filters: false,
      showEventDocumentsModal: false,
      eventDocuments: []
    };

    this.onRegisterEvent = this.onRegisterEvent.bind(this);
    this.onUnregisterEvent = this.onUnregisterEvent.bind(this);
    this.onSortingChange = this.onSortingChange.bind(this);
    this.onFilterTrackChange = this.onFilterTrackChange.bind(this);
    this.onFilterCancelClick = this.onFilterCancelClick.bind(this);
    this.onFilterCheckAllTracks = this.onFilterCheckAllTracks.bind(this);
    this.toggleEventDocumentsModal = this.toggleEventDocumentsModal.bind(this);
  }

  componentWillMount()
  {
    const self = this;
    Promise.resolve(this.props.actions.loadIndividual(this.props.individualId))
    .then(async () => {
      if (this.props.individual && this.props.individual.guide_created === false) {
        this.props.actions.updateIndividual(this.props.individual.id, { guide_created: true })
      }
      const expo_id = self.props.individual.expo_id;
      await this.context.checkIfMarketplaceClosed(expo_id)
      Promise.resolve(this.props.actions.loadEvents(expo_id))
        .then(() => {
          self.setInitialFilters(self.props.events);
        });
      this.props.actions.loadExpo(expo_id);
    });
  }

  componentDidMount()
  {
  }

  componentWillReceiveProps(nextProps) {

  }

  onRegisterEvent(_event)
  {
    //
    const {individual} = this.props;
    let events_registered = [...individual.events_registered];

    if (!(events_registered.indexOf(_event.event_id) > -1)) {
      events_registered.push(_event.event_id);
    }

    this.props.actions.updateIndividual(individual.id, { events_registered: [...events_registered] });
  }

  onUnregisterEvent(_event)
  {
    const {individual} = this.props;
    let events_registered = [...individual.events_registered];
    events_registered = events_registered.filter((e) => e !== _event.event_id);

    this.props.actions.updateIndividual(individual.id, { events_registered: [...events_registered] });

  }

  onSortingChange(selection) {
    if (selection) {
      this.setState({
        sort_by: selection.value
      });
    }
  }

  sortEvents(events)
  {
    const sort_by = this.state.sort_by;
    return events.sort(firstBy(sort_by, {ignoreCase: true}).thenBy('event_start', {ignoreCase: true}));
  }

  onFilterTrackChange(event)
  {
    let filters = Object.assign({}, this.state.filters);
    let value = event.target.value;

    filters.tracks.forEach(track => {
      if (track.value === value) {
        track.selected = event.target.checked;
      }
    });
    filters.status.forEach(status => {
      if (status.value === value) {
        status.selected = event.target.checked;
      }
    });
    this.setState({filters: filters});
  }


  onFilterCancelClick()
  {
    const {show_filters} = this.state;
    this.setState({
      show_filters: !show_filters
    });
  }

  onFilterResetClick()
  {

  }

  onFilterCheckAllTracks(val, event)
  {
    event.preventDefault();
    const {filters} = this.state;
    filters.tracks.forEach(item => {
      item.selected = val;
    });
    this.setState({
      filters: Object.assign({}, filters)
    });
  }

  setInitialFilters(events) {
    let tracks = [];
    let _tracks = [];
    const {filters} = this.state;
    events.forEach(e => {
      const obj = {text: e.event_track, value: e.event_track, selected: true};
      if (e.event_track) {
        if (!(_tracks.indexOf(e.event_track) > -1)) {
          tracks.push(obj);
          _tracks.push(e.event_track);
        }
      }
    });

    filters.tracks = [...tracks.sort(firstBy('text', {ignoreCase: true}))];
    this.setState({
      filters: Object.assign({}, filters)
    });
  }

  filterEvents(individual, events)
  {
      const {filters} = this.state;
      const tracks = filters.tracks.filter(c => {
        return c.selected == true;
      });

      let _tracks = tracks.map(t => t.value);


      let filtered = events.filter(e => {
        const trackMatch = _tracks.indexOf(e.event_track) > -1;
        const attending = individual.events_registered.indexOf(e.event_id) > -1;

        return trackMatch && ((attending && filters.status[0].selected) || (!attending && filters.status[1].selected));
      });

      return [...filtered];
  }

  toggleEventDocumentsModal(documents) {
    this.setState({ showEventDocumentsModal: !this.state.showEventDocumentsModal, eventDocuments: documents || [] })
  }

  render() {
    const {sort_by, filters, show_filters, showEventDocumentsModal, eventDocuments} = this.state;
    const {expo, individual, events} = this.props;
    let filtered_events = [];
    let sorted_events = [];
    if (events && events.length > 0) {
      filtered_events = this.filterEvents(individual, events);
      sorted_events = this.sortEvents([...filtered_events]);
    }
    let filtered = false;
    filters.tracks.forEach(track => {
      if (track.selected === false) {
        filtered = true;
      }
    });
    filters.status.forEach(status => {
      if (status.selected === false) {
        filtered = true;
      }
    });


    return (
      <div>
        <Head expo={expo} individual={individual} eventsPage/>
        {showEventDocumentsModal && <EventDocumentsModal onClose={() => this.toggleEventDocumentsModal()} showModal={showEventDocumentsModal} eventDocuments={eventDocuments} />}
        <div className="row buyers-guide">
          <div className="guide-data-main col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
            <Header individual={individual} expo={expo} eventsPage/>
            <EventList
                  expo={expo}
                  individual={individual}
                  events={sorted_events}
                  onRegisterEvent={this.onRegisterEvent}
                  onUnregisterEvent={this.onUnregisterEvent}
                  sortedBy={sort_by}
                  onSortingChange={this.onSortingChange}
                  showFilters={show_filters}
                  filters={filters}
                  onFilterTrackChange={this.onFilterTrackChange}
                  onFilterCancelClick={this.onFilterCancelClick}
                  onFilterCheckAllTracks={this.onFilterCheckAllTracks}
                  filtered={filtered}
                  toggleEventDocumentsModal={this.toggleEventDocumentsModal}
                  />
            <Footer/>
          </div>
        </div>
      </div>
    );
  }
}

EventsPage.propTypes = {
  events: PropTypes.array.isRequired,
  expo: PropTypes.object.isRequired,
  individual: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  individualId: PropTypes.string.isRequired
};


EventsPage.contextType = AuthContext;

function mapStateToProps(state, ownProps){
  return {
    individualId: ownProps.match.params.id,
    events: state.events,
    expo: state.expo,
    individual: state.individual
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, eventActions, guideActions), dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsPage);
