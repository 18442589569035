import React from 'react';

const Spinner = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <div className="fa-3x">
        <i className="fa fa-circle-o-notch fa-spin" />
      </div>
    </div>
  );
};

export default Spinner;
