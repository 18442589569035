import React from 'react';
import CategoriesTree from '../common/CategoriesTree';
import PropTypes from 'prop-types';

const CategoriesManage = ({individual, categories, checkedKeys, expandedKeys, onCheck, onSelect, onExpand, keywords, onKeywordsChange, errors}) => {
  let container =[];
const containerArray = Object.keys(keywords);
  for (let i = 0; i < 10; i++) {
    container.push(<div className="form-group" key={`filter_keyword_div_${i}`}>
      <input type="text" className="form-control" value={keywords[i]} name={`filter_keyword__${i}`} key={`filter_keyword__${i}`} onChange={onKeywordsChange} />
    </div>);
  }
  return (
      <div className="guide-data-box margin-top-0">
        <div className="col-md-12">
            {errors && errors.category_length_validation &&
              <div>
                <div className="alert alert-danger">
                  <button
                    type="button"
                    data-dismiss="alert"
                    aria-label="Close"
                    className="close">
                    <span aria-hidden="true">×</span>
                  </button>
                  <span>
                    Please select at least one category to create your Personal Guide.
                  </span>
                </div>
              </div>
            }
        </div>
        <div className="col-md-7 col-sm-6 col-xs-12 hidden-print">
          <table style={{width: '100%', fontSize:'15px'}}>
            <tbody>
              <tr>
                <td className="hidden-print">
                  <span className="title">Categories </span><br /><span style={{fontSize:'15px'}}>Check all categories that interest you</span>
                </td>
              </tr>
              <tr>
                <td>
                  <CategoriesTree onSelect={onSelect} onExpand={onExpand} onCheck={onCheck} categories={categories} checkedKeys={checkedKeys} expandedKeys={expandedKeys} checkable  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-5 col-sm-6 col-xs-12 hidden-print">
          <table style={{width: '100%'}}>
            <tbody>
              <tr>
                <td><span className="title">Keywords </span>
                <br />
                <span style={{fontSize:'15px'}}>Enter <strong>keywords</strong> to describe products, information or services that interest you</span>
                </td>
              </tr>
              <tr>
                <td className="text-primary">
                    {
                      containerArray.map((item,i) => {
                      return (<div className="form-group" key={`div_${item}`}>
                        <input type="text" className="form-control" value={keywords[`keyword_${i}`]} name={`${item}`} key={`${item}`} onChange={onKeywordsChange} />
                      </div>);
                    })
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  );
};

CategoriesManage.propTypes = {
  individual: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  keywords: PropTypes.object.isRequired,
  checkedKeys: PropTypes.array.isRequired,
  expandedKeys: PropTypes.array.isRequired,
  onCheck: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  onKeywordsChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};


export default CategoriesManage;
