import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function eventsReducers(state = initialState.events, action) {
  switch (action.type) {
    case types.LOAD_EVENTS_SUCCESS:
      return action.events;
    default:
      return state;

  }
}
