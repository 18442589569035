import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReadMore from '../common/ReadMore'

const propTypes = {
  expo: PropTypes.object.isRequired,
  exhibitor: PropTypes.object.isRequired
};

const ExhibitorItemPrint = ({expo, exhibitor}) => {
  const activeClassName = " btn-active-interest";
  const defaultClassName = " btn-default";
  let interestedClassName = 'btn btn-squared btn-wide btn-sm margin-right-5';
  let maybeClassName = 'btn btn-squared btn-wide btn-sm margin-right-5';
  let notInterestedClassName = 'btn btn-squared btn-wide btn-sm margin-right-5';

  interestedClassName = exhibitor.interest === 'interested'
    ? interestedClassName + activeClassName
    : interestedClassName + defaultClassName;
  maybeClassName = exhibitor.interest === 'maybe'
    ? maybeClassName + activeClassName
    : maybeClassName + defaultClassName;
  notInterestedClassName = exhibitor.interest === 'notinterested'
    ? notInterestedClassName + activeClassName
    : notInterestedClassName + defaultClassName;

  let exhibitor_photo = exhibitor.company_logo || ('https://s3.amazonaws.com/exposync-uploads/' + expo.expo_default_guide_image_url);

  return (
    <div className="guide-data-box" style={{
      borderLeft: '5px solid #ff7e82',
      borderRight: '5px solid #ff7e82'
    }}>
      <div className="row">
        <div className="col-md-10 col-sm-10 col-xs-10">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-6">
              <span className="title">
                {exhibitor.exhibitor_name}
              </span>
            </div>
            <div className="col-md-4 col-xs-4 text-bold pull-right">
              <span>
                {exhibitor.booth_location}
              </span>
              <span>&nbsp;</span>
              <span>Booth:
              </span>
              <span>
                {exhibitor.booth_number}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-xs-4">
              <table style={{
                width: '100%',
                borderCollapse: 'collapse'
              }}>
                <tbody>
                  {exhibitor.matched_categories && exhibitor.matched_categories.length > 0 && <tr>
                    <td>
                      <div className="text-bold">Category Match</div>
                      <span className="text-bricky" style={{
                        verticalAlign: 'top',
                        wordWrap: 'break-word'
                      }}>
                        {exhibitor.matched_categories.join(', ')}
                      </span>
                    </td>
                  </tr>
}
                  {exhibitor.matched_keywords && exhibitor.matched_keywords.length > 0 && <tr>
                    <td>
                      <div className="text-bold">Keyword Match</div>
                      <span style={{
                        verticalAlign: 'top',
                        wordWrap: 'break-word'
                      }}>
                        {exhibitor.matched_keywords.join(', ')}
                      </span>
                    </td>
                  </tr>
}
                </tbody>
              </table>
            </div>
            <div className="col-md-8 col-xs-12">
              <ReadMore lines={6} backgroundColor={'#FFFFFF'}>
                {exhibitor.exhibitor_description}
              </ReadMore>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-md-offset-4">
              <button type="button" className={interestedClassName}>I'm interested</button>
              <button type="button" className={maybeClassName}>Maybe interested</button>
              <button type="button" className={notInterestedClassName}>No (Remove)</button>
            </div>
          </div>
        </div>
        <div className="col-md-2 col-sm-2 col-xs-2" style={{
          margin: '0px',
          padding: '0px',
          marginLeft: '-10px'
        }}>
          {exhibitor_photo && <img width="90%" src={`${exhibitor_photo}?cache=none`} className="img-thumbnail pull-right"/>}

        </div>
      </div>
    </div>
  );
};

ExhibitorItemPrint.propTypes = propTypes;

export default ExhibitorItemPrint;
