import React from 'react';
import {Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';

const propTypes = {
  expo: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

const GuideTipsModal = ({expo, showModal, onClose}) => {
  return (
      <Modal show={showModal} onHide={onClose} bsSize="large">
        <Modal.Header closeButton className="bg-white">
          <Modal.Title>Guide Tips</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          <div style={{maxHeight: '600px', overflowY: 'auto'}}>
            <div style={{fontSize: '15px', marginTop: '-20px'}} className="row buyers-guide">
            <div className="guide-data-main col-md-12">
              <div className="guide-data-box">
                <table style={{width: '100%'}}>
                  <tbody><tr>
                    <td style={{textAlign:'center'}}>
                      {expo && expo.expo_logo_url && <img loading="lazy" style={{maxHeight: '100px', maxWidth: '100%'}} src={`https://s3.amazonaws.com/exposync-uploads/${expo.expo_logo_url}`} />}
                    </td>
                  </tr>
                  <tr>
                    <td style={{textAlign:'center'}} className="padding-top-10"><span className="title">Personal Guide USER TIPS</span></td>
                  </tr>
                  <tr>
                    <td>
                      <p><span>Personal Guides for &nbsp;</span><span className="text-bold">{expo.expo_name}</span><span>&nbsp;provide an easy way to:</span>
                        </p><ul className="list-unstyled">
                          <li> <span>(i)&nbsp;</span><span>
                              <u>preview exhibitors</u></span><span>&nbsp;offering products that match your </span><span>
                              <u>personal needs,</u></span><span>&nbsp;and </span></li>
                          <li> </li><span>(ii) develop your </span><span>
                            <u>list of “interesting” exhibitors</u></span><span>&nbsp;to meet.</span>
                        </ul>
                      <p></p>
                      <p><span className="text-bold">About the Guides</span>
                        </p><ul>
                          <li>
                            <span>Personal Guide is uniquely created for </span><span>
                              <u>you</u></span><span>.</span>
                          </li>
                          <li> <span>The Guide will only display the exhibitors that match your interests.</span></li>
                          <li> <span>Personal Guides are </span><span>
                              <u>dynamic</u></span><span> – meaning that as you update your interests or exhibitors update their information, your Personal Guide will always have the latest and most accurate information.</span></li>
                        </ul>
                      <p></p>
                      <p><span className="text-bold">How to Use Your Guide
                          </span></p><ul>
                            <li>Click the Categories that interest you most.  You can also add “keywords” to describe the products you want to find.  Your Guide will then do all the work for you.</li>
                            <li>There is no need to download an “app”, create user name or password.  Your preferences will give you all matching exhibitors at once. </li>
                            <li> <span>
                                <u>It’s easy</u></span><span> to review Exhibitors matching your interests and click on their websites for additional information.</span></li>
                            <li> <span>If an exhibitor is not a good fit for your needs, simply click that you are not interested and the Exhibitor will be </span><span>
                                <u>removed</u></span><span> from your Guide, leaving only exhibitors that match your needs.</span></li>
                            <li> <span>Click that you are “interested” in exhibitors and they will frequently send special offers.</span></li>
                          </ul><p></p>
                      <p><span className="text-bold">Saving and Accessing Your Guide
                          </span></p><ul>
                            <li> <span>Since your Guide is delivered as a webpage, all you need to do is </span><span className="text-bold">“bookmark”</span><span> or </span><span className="text-bold">“save to Favorites” </span><span>your Personal Guide webpage.</span></li>
                            <li>You can access this webpage through any device (computer, tablet, phone) to view your Guide. </li>
                          </ul><p></p>
                      <p><span className="text-bold">Sorting and Printing Your Guide
                          </span></p><ul>
                            <li>Your Guide can be sorted alphabetically or by booth location/number.</li>
                            <li>Click “Print My Guide” to keep a paper version of your Personal Guide.  You can also save the print version as a PDF file for easy electronic viewing.</li>
                          </ul><p></p>
                      <p>
                        </p><div className="text-bold">Bookmarking Your Guide</div>
                        <p>Some Buyers have asked -- How can I save my Guide?  Here are some quick tips:</p>
                        <ul>
                          <li>
                            <div className="text-bold">For Computer Users (PC or Mac)</div>
                            <div>To bookmark your Personal Guide on a PC or Mac open your Guide in your favorite browser and press CTRL+D (Command+D on a Mac) to bookmark the page. </div><br/>
                            <div><img src="https://s3.amazonaws.com/exposync-uploads/uploads_861_pc-mac-steps-combined.png" /></div><br/>
                          </li>
                          <li>
                            <div className="text-bold">For iPhone &amp; iPad Users</div>
                            <div>Open your Guide from the Safari browser. Tap the Share button on the browser’s toolbar — that’s the rectangle with an arrow pointing upward. It’s on the bar at the bottom of the screen on an iPhone, and on the bar at the top of the screen on an iPad.  Tap the Add to Home Screen icon in the Share menu.  Your Personal Guide icon will appear, just like an App.  You can also add your Guide as a Favorite or a Bookmark, if you prefer.</div><br />
                            <div><img src="https://s3.amazonaws.com/exposync-uploads/uploads_88986_ios-share-1.png" width="100%" /></div><br/>
                          </li>
                          <li>
                            <div className="text-bold">For Android Users</div>
                            <div>Open your Guide from the Chrome for Android app. Tap the menu button and tap Add to Home screen. You’ll be able to enter a name for the shortcut and then Chrome will add it to your Home screen.</div><br />
                            <div><img src="https://s3.amazonaws.com/exposync-uploads/uploads_54206_android-steps-combined.png" width="100%" /></div><br/>
                          </li>
                        </ul>
                      <p></p>
                    </td>
                  </tr>
                </tbody></table>
              </div>
            </div>
          </div>
          </div>
          </Modal.Body>
        <Modal.Footer className="bg-white">
          <button className="btn btn-squared btn-sm btn-wide btn-air-red" onClick={onClose}>Close</button>
        </Modal.Footer>
      </Modal>
  );
};

GuideTipsModal.propTypes = propTypes;


export default GuideTipsModal;
