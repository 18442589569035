import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function syncReducers(state = initialState.synced, action) {
  switch (action.type) {
      case types.STORE_SYNCED:
        return action.synced;
    default:
      return state;

  }
}
