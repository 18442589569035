export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';

export const STORE_SYNCED = 'STORE_SYNCED';

export const LOAD_INDIVIDUAL_SUCCESS = 'LOAD_INDIVIDUAL_SUCCESS';
export const UPDATE_INDIVIDUAL_SUCCESS = 'LOAD_INDIVIDUAL_SUCCESS';

export const LOAD_EXPO_SUCCESS = 'LOAD_EXPO_SUCCESS';
export const LOAD_EVENTS_SUCCESS = 'LOAD_EVENTS_SUCCESS';

export const LOAD_EXHIBITORS_SUCCESS = 'LOAD_EXHIBITORS_SUCCESS';
export const UPDATE_EXHIBITOR_SUCCESS = 'UPDATE_EXHIBITOR_SUCCESS';
