/* eslint-disable react/no-multi-comp */
import React from 'react';
import Tree, { TreeNode } from 'rc-tree';
import PropTypes from 'prop-types';

const loop = (data) => {
    return data.map((item) => {
      if (item.children) {
        return <TreeNode title={item.name} key={item.key} expanded>{loop(item.children)}</TreeNode>;
      }
      return (
        <TreeNode className="treenode" title={item.name} key={item.key} expanded isLeaf={item.isLeaf}
          disabled={item.key === '0-0-0'}
        />
      );
    });
  };

const CategoriesTree = ({categories, checkedKeys, expandedKeys, onSelect, onExpand, onCheck, checkable, defaultExpandAll}) => {
  const treeNodes = loop(categories);
  return (
      <div>
      {!checkable && <Tree
        showLine checkable={checkable} defaultExpandAll={defaultExpandAll} showIcon={false}
        onSelect={onSelect}
        onCheck={onCheck} checkedKeys={checkedKeys}
        >
        {treeNodes}
      </Tree>}
      {checkable && <Tree
        showLine checkable={checkable} showIcon={false}
        onSelect={onSelect}
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        onCheck={onCheck} checkedKeys={checkedKeys}
        >
        {treeNodes}
      </Tree>}
      </div>
  );
};

CategoriesTree.propTypes = {
  categories: PropTypes.array.isRequired,
  checkedKeys: PropTypes.array,
  expandedKeys: PropTypes.array,
  onCheck: PropTypes.func,
  onSelect: PropTypes.func,
  onExpand: PropTypes.func,
  checkable: PropTypes.bool,
  defaultExpandAll: PropTypes.bool
};


export default CategoriesTree;
