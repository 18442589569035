

function filterNodes(nodes, value){
  let list = [];
  const self = this;
  nodes.forEach(function(node) {
    if (node.key == value) {
      list.push(node);
    }
    if (node.children) {
      list = list.concat(filterNodes(node.children, value));
    }
  });

  return list;
}

export function toTreeFormat (categories){
  if (!categories)
    return [];
  let tree = [];
  let list = [];
  categories = categories.sort();

  categories.forEach(function(item) {
    let nodes = item.split(':');
    let processedNodes = [];
    let parentNode = '';

    nodes.forEach(function(node) {
      let category = '';
      parentNode = '';

      if (processedNodes.length > 0) {
        parentNode = processedNodes.join(':');
        category = processedNodes.join(':') + ':' + node;
      } else
        category = node;

      let matches = filterNodes(tree, category);

      if (matches.length === 0) {
        if (!parentNode) {
          let next = {
            key: category,
            name: node
          };
          tree.push(next);
        } else {
          matches = filterNodes(tree, parentNode);
          if (matches.length > 0) {
            if (node != category) {
              if (matches[0].children == undefined) {
                matches[0].children = [];
              }
              matches[0].children.push({key: category, name: node, isLeaf: true});
            }
          }
        }

      }
      processedNodes.push(node);
    });
  });
  return tree;
}
